import { IconButton, Tooltip, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import moment from "moment"
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
})

const ClasesAsistenciaTable = (props) => {
    const clases = Styles()

    const {
        desvincular,
        data,
        changeAsist,
        modal,
        pageData,
        changePage,
        loading,
        danes,
        tipo,
        maximize,
        handleSelect,
        removeRecord = null,
        addRecord = null,
        select,
        tandaSelect,
    } = props

    console.log({ data })
    const columns = [
        {
            field: "sel",
            headerName: "Asistencia",
            minWidth: 180,
            renderCell: (row) => {
                if (row.row.estado == true) {
                    return (
                        <IconButton
                            style={{ color: "green", width: "100%" }}
                            onClick={() => changeAsist(row.row.id, row.row.estado)}
                        >
                            <CheckCircleOutlineIcon fontSize="large" />
                        </IconButton>
                    )
                } else {
                    return (
                        <IconButton
                            style={{ color: "white", width: "100%" }}
                            onClick={() => changeAsist(row.row.id, row.row.estado)}
                        >
                            <Tooltip title="Incluir a tanda">
                                <RadioButtonUncheckedIcon />
                            </Tooltip>
                        </IconButton>
                    )
                }
            },
        },

        { field: "nombre_completo", headerName: "Nombre", minWidth: 350 },
        {
            field: "edad",
            headerName: "Edad",
            minWidth: 110,
            renderCell: (row) => <div>{row.row.edad || "No Indicado"}</div>,
        },
        //  { field:'telefono', headerName: 'Telefono',minWidth: 120, renderCell: row => <div>{row.row.telefono || 'No Indicado'}</div>},
        { field: "categoria", headerName: "Categoria Actual", minWidth: 190 },

        {
            field: "cuota",
            headerName: "Mensualidad",
            width: 180,
            renderCell: (row) => {
                if (row.row.cuota == 1) {
                    return (
                        <Typography style={{ color: "lightgreen", fontWeight: "bold", display: "flex" }}>
                            {" "}
                            <CheckIcon style={{ marginRight: 10 }} /> {moment(row.row.fechaCuota).format("DD-MM-YYYY")}
                        </Typography>
                    )
                } else {
                    return (
                        <Typography style={{ color: "orange", display: "flex" }}>
                            {" "}
                            <CloseIcon style={{ marginRight: 10 }} /> Adeuda Pago
                        </Typography>
                    )
                }
            },
        },

        {
            field: "desvincular",
            headerName: "Desvincular",
            width: 140,
            renderCell: (row) => {
                return (
                    <IconButton style={{ color: "white", width: "100%" }} onClick={() => modal(true, row.row)}>
                        <Tooltip title="Quitar de la clase">
                            <PlaylistRemoveIcon fontSize="large" />
                        </Tooltip>
                    </IconButton>
                )
            },
        },
    ]

    return (
        <div className={clases.tableContainer}>
            {data?.length ? (
                <DataGrid
                    autoHeight={true}
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={data}
                    pageSize={90}
                    loading={loading}
                    columns={columns}
                    paginationMode="server"
                    isRowSelectable={false}
                    //  onPageChange={changePage}
                    // rowsPerPageOptions={[10,20,30]}
                    rowCount={data.length}
                    // page={pageData.currentPage}
                />
            ) : (
                <div className={clases.noDataContainer}>
                    <h2 className={clases.noDataText}>No se encontraron alumnos</h2>
                </div>
            )}
        </div>
    )
}

export default ClasesAsistenciaTable
