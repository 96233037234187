import React, { useEffect, useState } from "react"
import Container from "../Components/Container/Container"
import { fetchResource } from "../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField"
import Modal from "@material-ui/core/Modal"
import ModalBody from "../Components/ModalBody/ModalBody"
import ActionsTable from "../Components/Table/ActionsTable"
import { UsersTableBasicColumns } from "../Components/Table/AppColumns"
import { Checkbox, Button } from "@material-ui/core"
import { border, padding } from "@mui/system"

const Torneo = () => {
    const [loading, setLoading] = useState(true)
    const [isIncripted, setIsIncripted] = useState(false)
    const [fields, setFields] = useState(false)
    const [alerta, setAlerta] = useState(false)
    const [submited, setSubmited] = useState(false)
    const [verfyData, setVerifyData] = useState(false)
    const [transmition, setTransmition] = useState(false)
    const [userData, setUserData] = useState({
        altura: "",
        peso: "",
    })

    useEffect(() => {
        const checkInstription = async () => {
            const [data, error] = await fetchResource("/users/torneo/checkInscription", "get", true, null)
            if (data) {
                setLoading(false)
                setIsIncripted(true)
            } else {
                setLoading(false)
                setIsIncripted(false)
            }
        }
        checkInstription()
    }, [submited])

    const handleSubmit = async () => {
        if (verfyData && transmition) {
            if (userData.altura && userData.peso) {
                setLoading(true)
                const [data, error] = await fetchResource("/users/torneo/registerIntern", "post", true, userData)
                if (data) {
                    setSubmited(true)
                }
            } else {
                setFields(true)
            }
        } else {
            setAlerta(true)
        }
    }

    const hanldeInputChange = (e) => {
        const value = e.target.value
        const target = e.target.name

        switch (target) {
            case "transmition":
                setTransmition(!transmition)
                break
            case "data":
                setVerifyData(!verfyData)
                break
            case "altura":
                setUserData({ ...userData, altura: value })
                break
            case "peso":
                setUserData({ ...userData, peso: value })
                break

            default:
                break
        }
    }

    return (
        <div className="alumnos-container">
            {loading ? (
                <Container title="Inscripcion Torneo FIT" more>
                    <CircularProgress />
                </Container>
            ) : (
                <Container title="Inscripcion Torneo FIT" more>
                    {isIncripted ? (
                        <div style={{ textAlign: "center" }}>
                            <h2>¡Ya se encuentra inscripto en el Torneo!</h2>
                        </div>
                    ) : (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    padding: "1em",
                                    marginTop: "1em",
                                }}
                            >
                                <h2>¡IMPORTANTE!</h2>
                                <p>
                                    El Evento sera transmitido en su totalidad via Streaming por el canal de Youtube de
                                    FIT Asociación, el cual es de acceso publico; al completar la presente inscripción,
                                    con la carga de sus datos o los de su hijo, la Organización considera que se le esta
                                    dando <b>TOTAL AUTORIZACION</b> para poder filmar o tomarles foto, asi como la
                                    difusión de las mismas, siempre en el ambito del evento o de futuras promociones de
                                    eventos/actividades.
                                </p>
                                <p>
                                    Es muy importante, <b>VERIFIQUE</b>, que todos los datos esten actualizados.
                                </p>
                                <dvi
                                    style={
                                        alerta
                                            ? {
                                                  border: "1px solid red",
                                                  color: "red",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  flexDirection: "column",
                                                  paddingLeft: "1em",
                                              }
                                            : {
                                                  border: "1px solid",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  flexDirection: "column",
                                                  paddingLeft: "1em",
                                              }
                                    }
                                >
                                    {alerta && <h3>Debe aceptar las condiciones</h3>}
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginTop: "0.5em",
                                        }}
                                    >
                                        <label for="transmition">Autorizo la transmisión por YouTube</label>
                                        <Checkbox
                                            name="transmition"
                                            id="transmition"
                                            onChange={hanldeInputChange}
                                            value={transmition}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginTop: "0.5em",
                                        }}
                                    >
                                        <label for="data">Voy a Verificar todos los datos.</label>
                                        <Checkbox
                                            name="data"
                                            id="data"
                                            onChange={hanldeInputChange}
                                            value={verfyData}
                                        />
                                    </div>
                                </dvi>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <p>
                                    <h3>Ingrese su peso y altura</h3>
                                    (No utilice puntos ni comas)
                                </p>
                                {fields && (
                                    <div style={{ color: "red" }}>
                                        <p>Debe Compleatar todos los campos</p>
                                    </div>
                                )}
                                <TextField
                                    autoComplete="nope"
                                    id="altura"
                                    name="altura"
                                    label="Altura cm"
                                    onFocus={() => setFields(false)}
                                    value={userData.altura}
                                    onChange={hanldeInputChange}
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    required
                                />
                                <TextField
                                    autoComplete="nope"
                                    id="peso"
                                    name="peso"
                                    label="Peso Kg"
                                    onFocus={() => setFields(false)}
                                    value={userData.peso}
                                    onChange={hanldeInputChange}
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    required
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: "red" }}
                                    disabled={!(transmition && verfyData)}
                                    onClick={handleSubmit}
                                >
                                    Inscribirme
                                </Button>
                            </div>
                        </>
                    )}
                </Container>
            )}
        </div>
    )
}

export default Torneo
