import React, { useEffect, useState } from "react"
import Container from "../Components/Container/Container"
import { fetchResource, useDataFilter, useRageFilter } from "../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import AlumnosExamenTable from "../Components/Table/AlumnosExamenTable"
import DefaultMessage from "../Components/DefaultMessage/DefaultMessage"
import Modal from "@material-ui/core/Modal"
import ModalBody from "../Components/ModalBody/ModalBody"
import TextField from "@material-ui/core/TextField"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import Selection from "../Components/Formularios/Select"

const AlumnosExamenInstructor = () => {
    const examen = useParams("id")

    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))
    const user = useSelector((state) => state.userReducer.user)

    const { categorias } = state.basicData

    const [loading, setLoading] = useState(true)
    const [modalOpen, setModalOpen] = useState(false)
    const [alumnos, setAlumnos] = useState(null)
    const [filtro, setFiltro] = useState(null)

    const [setNameFilter, nameFilterValue, filteredNameData] = useDataFilter(alumnos, "fullName")
    const [setFrom, setTo, from, to, filteredAgeData] = useRageFilter(filteredNameData, "edad")
    const [setCategoriaFilter, categoriaFilterValue, filteredCategoriaData] = useDataFilter(
        filteredAgeData,
        "categoria",
        "exact"
    )

    useEffect(() => {
        const fetchAlumnos = async () => {
            const [data, error] = await fetchResource(
                `/instructor/examen/showInscriptosExam/${examen.id}`,
                "get",
                true,
                null
            )

            if (data) {
                setLoading(false)
                setAlumnos(
                    data.inscriptos.map((alumno) => {
                        return {
                            ...alumno,
                            fullName: `${alumno.nombre} ${alumno.apellido}`,
                            categoria: alumno.categoria.nombre,
                        }
                    })
                )
            } else {
                setLoading(false)
            }
        }

        fetchAlumnos()
    }, [])

    const handleDelete = async (id) => {
        const parametro = {
            user_id: id,
            examen_id: examen.id,
        }
        const [data, error] = await fetchResource("/instructor/examen/unsignToExam", "post", true, parametro)

        if (data) {
            setLoading(false)
            let filterAlumnos = alumnos.filter((alumno) => alumno.id !== id)
            if (filterAlumnos.length) {
                setAlumnos(filterAlumnos)
            } else {
                setAlumnos(null)
            }
        } else {
            setLoading(false)
        }
        setModalOpen(false)
    }

    const handleChange = (e) => {
        setFiltro(e.target.value)
    }
    // Modal

    const handleClose = () => {
        setModalOpen(false)
    }

    // Modal
    console.log(filteredCategoriaData)

    return (
        <div className="alumnos-container">
            <Container title="Alumnos Inscriptos" more={true}>
                <div style={{ display: "flex", marginTop: "1em" }}>
                    {/* <TextField type="text" name="nombre" label="Nombre o Apellido" value={nameFilterValue} onChange={setNameFilter} size="small" variant="outlined" />
                    <Selection
                        label="Categoria"
                        name="categoria"
                        value={categoriaFilterValue}
                        onChange={setCategoriaFilter}
                        options={categorias}
                    />
                    <TextField
                        variant="outlined"
                        size="small"
                        label="Edad-Desde"
                        name="from"
                        type="number"
                        value={from}
                        onChange={setFrom}
                    />
                    <TextField
                        variant="outlined"
                        size="small"
                        label="Edad-Hasta"
                        name="to"
                        type="number"
                        value={to}
                        onChange={setTo}
                    /> */}
                </div>
                {loading ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <>
                        {filteredCategoriaData ? (
                            <AlumnosExamenTable setModalOpen={setModalOpen} data={filteredCategoriaData} />
                        ) : (
                            <DefaultMessage logo title={"No hay alumnos inscriptos"} />
                        )}
                    </>
                )}
            </Container>
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <ModalBody
                    handleDelete={handleDelete}
                    setModalOpen={setModalOpen}
                    modalOpen={modalOpen}
                    password={true}
                />
            </Modal>
        </div>
    )
}

export default AlumnosExamenInstructor
