import React, { useEffect, useState } from "react"

import { fetchResource, usePermitions } from "../../../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useSelector } from "react-redux"
import { makeStyles, Select, MenuItem, option, Typography, TextField, Button } from "@material-ui/core"
import toast, { Toaster } from "react-hot-toast"
//import InstructorInscribeExamenTable from './InstructorInscribeExamenTable'
//import { InscripcionExamenDataFilters } from './InscripcionExamenDataFilters';
import { useLocation } from "react-router-dom"
import moment from "moment"

const Styles = makeStyles({
    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },
    fecha: {
        width: 300,
        height: 80,
        margin: 10,
        marginTop: 0,
        justifyContent: "center",
        "& .MuiInputBase-root": {
            fontSize: 40,
            padding: 10,
        },
    },

    top: {
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 5,
        marginBottom: 20,
        marginTop: 10,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 10,
        "@media (min-width: 1200px)": { flexDirection: "row" },
    },
})

const CuotaRegistroPagoTopInfo = (props) => {
    const { btnvolver, permitido, btnalumnos, datos, fecha, nombreClase, changeMes, meses } = props

    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))
    const { basicData } = state
    const { role_id } = usePermitions()
    const location = useLocation()
    const examenesActivos = location.state
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [alumnos, setAlumnos] = useState([]) // guarda los id y nombres de los alumnos
    const [clases, setClases] = useState("") // guarda todas las clases que va a mostrar en Select
    const [records, setRecords] = useState(0)
    const [modal, setModal] = useState(false)

    //   const [dia, setDia] = useState(moment().format("YYYY-MM-DD"))

    const paramsInitialValues = {
        page: 0,

        nombre: "",
        apellido: "",
        escuela: "",
        asociacion: "",
        categoria: "",
        instructor: "",
        edadDesde: "",
        edadHasta: "",
        actives: true,
        claseSelected: "",
        rowsPerPage: 10,
    }
    const [params, setParams] = useState(paramsInitialValues)

    const fetchClases = async () => {
        const [data, error] = await fetchResource(`/menu/clases`, "GET", true, null)

        if (data) {
            setLoading(false)
            setClases(data.data)
        } else {
            setLoading(false)
            // console.log(error)
        }
    }

    const notify = (x, nombre) => {
        let mensaje = ""

        if (x == 1) {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Correcto."
            }
            toast.success(mensaje, { style: { background: "lightgreen", color: "Black" } })
        } else {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Eliminado"
            }
            toast.error(mensaje, { style: { background: "red", color: "white" } })
        }
    }

    /*---------------------------*/
    const conteo = (tipo) => {
        //tipo=0 ausentes ,tipo=1 presentes
        let c = 0

        let pago = " --"
        let debe = " --"
        let porPago = " --"
        let porDebe = " --"

        if (datos !== "") {
            pago = 0
            debe = 0
            porPago = 0
            porDebe = 0

            datos.map((x) => {
                if (x.estado == 1) {
                    pago = pago + 1
                } else {
                    debe = debe + 1
                }
            })
            porPago = ((pago * 100) / datos.length).toFixed(1)
            porDebe = ((debe * 100) / datos.length).toFixed(1)
        }

        if (tipo == 1) {
            c = { pago: pago, porcent: porPago }
        } else {
            c = { debe: debe, porcent: porDebe }
        }

        return c
    }

    const handleRemove = async (id) => {
        let parametro = { id: id }
        const [data, error] = await fetchResource("/instructor/alumnos/dettach", "post", true, parametro)

        if (error) console.error(error)
        let alumnosFiltrado = alumnos.filter((data) => data.id !== id)
        setAlumnos(alumnosFiltrado)
    }

    const classes = Styles()

    return (
        <div className={classes.top}>
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                <Typography variant="h6" style={{ width: "100%", textAlign: "center" }}>
                    {nombreClase.toUpperCase()}
                </Typography>

                <div style={{ width: "100%", textAlign: "center" }}>
                    <div style={{ width: "100%", textAlign: "center", display: "flex", flexDirection: "row" }}>
                        <div
                            style={{
                                padding: 5,
                                borderTopLeftRadius: 5,
                                borderStyle: "solid",
                                borderWidth: 1,
                                width: "50%",
                                textAlign: "center",
                            }}
                        >
                            <Typography style={{ fontSize: 15, width: "100%", textAlign: "center", color: "green" }}>
                                PAGAS ({conteo(1).porcent}%){" "}
                            </Typography>

                            <Typography style={{ fontSize: 25, width: "100%", textAlign: "center" }}>
                                {" "}
                                {conteo(1).pago}
                            </Typography>
                            <Typography style={{ fontSize: 20, width: "100%", textAlign: "center" }}> {}</Typography>
                        </div>

                        <div
                            style={{
                                padding: 5,
                                borderTopRightRadius: 5,
                                borderStyle: "solid",
                                borderWidth: 1,
                                width: "50%",
                                textAlign: "center",
                            }}
                        >
                            <Typography style={{ fontSize: 15, width: "100%", textAlign: "center", color: "red" }}>
                                IMPAGAS ({conteo(0).porcent}%){" "}
                            </Typography>
                            <Typography style={{ fontSize: 25, width: "100%", textAlign: "center" }}>
                                {" "}
                                {conteo(0).debe}
                            </Typography>
                        </div>
                    </div>

                    <div
                        style={{
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderBottomLeftRadius: 5,
                            borderBottomRightRadius: 5,
                            width: "100%",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <Typography style={{ width: "50%", textAlign: "center", margin: 5 }}>
                            TOTAL ALUMNOS:{" "}
                        </Typography>
                        <Typography style={{ width: "50%", textAlign: "center", margin: 5 }}>
                            {" "}
                            {datos.length}
                        </Typography>
                    </div>
                </div>
            </div>

            <div
                style={{
                    marginTop: 20,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="h6">PERIODO</Typography>
                <TextField
                    style={{ textAlign: "center" }}
                    className={classes.fecha}
                    select
                    onChange={(x) => changeMes(x.target.value)}
                    value={fecha}
                >
                    {meses.map((x) => {
                        return (
                            <MenuItem id={x.id} value={x.id}>
                                {x.mes}
                            </MenuItem>
                        )
                    })}
                </TextField>
            </div>

            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button onClick={() => btnalumnos()} style={{ background: "red", margin: 10, width: 100 }}>
                    Alumnos
                </Button>
                <Button onClick={() => btnvolver()} style={{ background: "red", margin: 10, width: 100 }}>
                    Volver
                </Button>
            </div>
        </div>
    )
}

export default CuotaRegistroPagoTopInfo
