import { IconButton, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import EditIcon from "@mui/icons-material/Edit"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import Button from "@mui/material/Button"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import { useNavigate } from "react-router-dom"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
})

const ActividadesTable = (props) => {
    const navigate = useNavigate()
    const clases = Styles()
    const { data, pageData, changePage, loading, status, remove, update, anterior, edit } = props

    const columns = [
        {
            field: "fecha",
            headerName: "Fecha",
            minWidth: 110,
            renderCell: (row) => <div>{row.row.fecha || "No Indicado"}</div>,
        },
        { field: "nombre", headerName: "Nombre", minWidth: 250, renderCell: (row) => <div>{row.row.nombre}</div> },
        {
            field: "lugar",
            headerName: "Lugar",
            minWidth: 190,
            renderCell: (row) => <div>{row.row.lugar || "No Indicado"}</div>,
        },
        {
            field: "actions",
            headerName: "Inscripcion",
            minWidth: 150,
            renderCell: (row) =>
                status == 1 ? (
                    <Tooltip title="Desactivar Inscripcion">
                        <Button onClick={update} name={row.row.nombre} id={row.row.id} color="success" variant="text">
                            Activa
                        </Button>
                    </Tooltip>
                ) : (
                    <>
                        {status == 2 ? (
                            <Tooltip title="Activar Inscripcion ">
                                <Button onClick={update} name={2} id={row.row.id} color="warning" variant="text">
                                    Inactiva
                                </Button>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Terminado">
                                <Button name={3} id={row.row.id} color="warning" variant="text">
                                    TERMINADA
                                </Button>
                            </Tooltip>
                        )}
                    </>
                ),
        },
        {
            field: "edad_desde",
            headerName: "Edades",
            minWidth: 120,
            renderCell: (row) => <div>{row.row.edad_desde + " a " + row.row.edad_hasta + " años" || "Todas"}</div>,
        },
        {
            field: "categoria_id_desde",
            headerName: "Desde",
            minWidth: 150,
            renderCell: (row) => <div>{row.row.categoria_desde || "Todas"}</div>,
        },
        {
            field: "categoria_id_hasta",
            headerName: "Hasta",
            minWidth: 150,
            renderCell: (row) => <div>{row.row.categoria_hasta || "Todas"}</div>,
        },
        {
            field: "cupo_maximo_locales",
            headerName: "Local",
            minWidth: 100,
            renderCell: (row) => (
                <div>{row.row.cupo_maximo_locales !== null ? row.row.cupo_maximo_locales : "s./limite"}</div>
            ),
        },
        {
            field: "cupo_maximo_invitados",
            headerName: "Invitad.",
            minWidth: 100,
            renderCell: (row) => (
                <div>{row.row.cupo_maximo_invitados !== null ? row.row.cupo_maximo_invitados : "s/limite"}</div>
            ),
        },
        {
            field: "lista",
            headerName: "lista",
            minWidth: 40,
            renderCell: (row) => (
                <IconButton
                    onClick={() => {
                        navigate(`/actividades/inscriptos/${row.row.id}`)
                    }}
                >
                    <Tooltip title="Ver Lista">
                        <FormatListBulletedIcon style={{ borderRadius: 5, height: 20, width: 20 }} />
                    </Tooltip>
                </IconButton>
            ),
        },
        {
            field: "borrar",
            headerName: "borrar",
            minWidth: 40,
            renderCell: (row) =>
                row.row.inscripcion_activa == 0 && anterior == 0 ? (
                    <IconButton
                        onClick={() => {
                            remove({ nombre: row.row.nombre, id: row.row.id })
                        }}
                    >
                        <Tooltip title="eliminar">
                            <DeleteIcon style={{ borderRadius: 15 }} />
                        </Tooltip>
                    </IconButton>
                ) : (
                    <IconButton disabled={true}>
                        <Tooltip title="Activar Inscripcion">
                            <DeleteIcon style={{ borderRadius: 15 }} />
                        </Tooltip>
                    </IconButton>
                ),
        },
        {
            field: "edit",
            headerName: "editar",
            minWidth: 40,
            renderCell: (row) =>
                anterior == 0 ? (
                    <IconButton onClick={() => edit(row.row)}>
                        <Tooltip title="Editar">
                            <EditIcon style={{ borderRadius: 15 }} />
                        </Tooltip>
                    </IconButton>
                ) : (
                    <IconButton disabled={true}>
                        <Tooltip title="Accion No Permitida">
                            <EditIcon style={{ borderRadius: 15 }} />
                        </Tooltip>
                    </IconButton>
                ),
        },
    ]

    return (
        <div className={clases.tableContainer}>
            {console.log("ACTIV tabla ", data)}

            {data?.length > 0 ? (
                <DataGrid
                    pagination
                    autoHeight
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={data}
                    pageSize={10}
                    loading={loading}
                    columns={columns}
                    paginationMode="server"
                    isRowSelectable={false}
                    onPageChange={changePage}
                    rowsPerPageOptions={[10]}
                    rowCount={pageData.records}
                    page={pageData.currentPage}
                    editMode="cell"
                    cell
                />
            ) : (
                <div className={clases.noDataContainer}>
                    <h2 className={clases.noDataText}>No se encontraron Actividades</h2>
                </div>
            )}
        </div>
    )
}

export default ActividadesTable
