import React, { useState, useEffect } from "react"
import Container from "./Container/Container"
import { fetchResource } from "../Functions/Functions"
import MatriculasActual from "./Matriculas/MatriculasActual"
import { CircularProgress } from "@material-ui/core"
import { matriculasInitialData } from "../Pages/MatriculaUser/Matriculas"

const MatriculaLayout = () => {
    const [matricula, setMatricula] = useState(matriculasInitialData)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchMatriculaData = async () => {
            const [data, error] = await fetchResource("/users/matriculas/show", "get", true, null)
            if (data) {
                setMatricula(data.data)
                setLoading(false)
            } else {
                console.error(error)
                setMatricula([])
                setLoading(false)
            }
        }
        fetchMatriculaData()
    }, [])

    return (
        <Container title="Matricula" moreUrl={"/usuarios/matricula"}>
            {loading ? <CircularProgress /> : <MatriculasActual matricula={matricula} />}
        </Container>
    )
}

export default MatriculaLayout
