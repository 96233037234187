import React, { useEffect, useState } from "react"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import { fetchResource } from "../Functions/Functions"
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"
import UpdateHandler from "../Components/Formularios/Update/UpdateHandler"
import ProfilePic from "../Components/Chart/ProfilePic/ProfilePic"

const Profile = () => {
    const [fullUser, setFullUser] = useState(null)
    const [categorias, setCategorias] = useState(null)

    useEffect(() => {
        const fetchFullUser = async () => {
            const [data, error] = await fetchResource("/users/show", "get", true, null)

            if (data) {
                setFullUser(data.user)
            } else {
                console.log(error)
            }
        }

        const fetchData = async () => {
            const [data, error] = await fetchResource("/menu/datos", "get", false, null)

            if (data) {
                setCategorias(data.data.categorias)
            }
        }
        fetchData()
        fetchFullUser()
    }, [])

    return (
        <div className="profile-container">
            {fullUser && (
                <Paper
                    elevation={10}
                    style={{
                        display: "flex",
                        padding: 10,
                        alignItems: "center",
                        justifyContent: "space-around",
                        flexDirection: "row",
                        flexWrap: "wrap",
                    }}
                >
                    <ProfilePic user={fullUser} />
                    {categorias && <UpdateHandler fullUser={fullUser} categorias={categorias} />}
                </Paper>
            )}
        </div>
    )
}

export default Profile
