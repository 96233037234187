import React, { useEffect, useState } from "react"
import Container from "../Components/Container/Container"
import { fetchResource } from "../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import InstructorInscribeExamenTable from "./Instructor/InstructorInscribeExamenTable"
import DefaultMessage from "../Components/DefaultMessage/DefaultMessage"
import Modal from "@material-ui/core/Modal"
import ModalBody from "../Components/ModalBody/ModalBody"
import TextField from "@material-ui/core/TextField"

const AlumnosExamen = ({ user }) => {
    //test
    const [loading, setLoading] = useState(true)
    const [modalOpen, setModalOpen] = useState(false)
    const [alumnos, setAlumnos] = useState(null)
    const [filtro, setFiltro] = useState(null)

    useEffect(() => {
        const fetchAlumnos = async () => {
            const [data, error] = await fetchResource("/instructor/examen/showInscriptos", "get", true, null)

            if (data) {
                setAlumnos(data.inscriptos)
                setLoading(false)
            } else {
                setLoading(false)
            }
        }

        fetchAlumnos()
    }, [])

    const handleDelete = async (id) => {
        const parametro = { user_id: id }
        const [data, error] = await fetchResource("/instructor/examen/unsignToExam", "post", true, parametro)

        if (data) {
            setLoading(false)
            let filterAlumnos = alumnos.filter((alumno) => alumno.id !== id)
            if (filterAlumnos.length) {
                setAlumnos(filterAlumnos)
            } else {
                setAlumnos(null)
            }
        } else {
            setLoading(false)
        }
        setModalOpen(false)
    }

    const handleChange = (e) => {
        setFiltro(e.target.value)
    }
    // Modal

    const handleClose = () => {
        setModalOpen(false)
    }

    // Modal

    return (
        <div className="alumnos-container">
            <Container title="Alumnos Inscriptos2" more={true}>
                <TextField
                    type="text"
                    name="nombre"
                    label="Nombre o Apellido"
                    value={filtro}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                />
                {loading ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <>
                        {alumnos.length !== 0 ? (
                            <InstructorInscribeExamenTable
                                filtro={filtro}
                                user={user}
                                setModalOpen={setModalOpen}
                                data={alumnos}
                            />
                        ) : (
                            <DefaultMessage logo title={"No hay alumnos inscriptos"} />
                        )}
                    </>
                )}
            </Container>
            {/* <Modal open={modalOpen} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <ModalBody handleDelete={handleDelete} setModalOpen={setModalOpen} modalOpen={modalOpen} />
            </Modal> */}
        </div>
    )
}

export default AlumnosExamen
