import React, { useState, useEffect } from "react"
import Container from "../Components/Container/Container"
import CreateClaseHandler from "../Components/Formularios/Clase/CreateClaseHandler"
import { fetchResource } from "../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import ClaseTable from "../Components/Table/ClaseTable"
import DefaultMessage from "../Components/DefaultMessage/DefaultMessage"
import { ClasesFilters } from "./ClasesFilters"
import { Modal } from "@material-ui/core"
import ModalBody from "../Components/ModalBody/ModalBody"

const Clases = () => {
    const [listaClases, setListaClases] = useState([])
    const [loading, setLoading] = useState(true)
    const [centros, setCentros] = useState(null)
    const [visible, setVisible] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [datoDelete, setDatoDelete] = useState(null)

    useEffect(() => {
        const fetchClases = async () => {
            const [data, error] = await fetchResource("/instructor/clases/show", "get", true, null)

            if (data) {
                setLoading(false)
                setListaClases(data.data)
            } else {
                setLoading(false)
                // console.log(error)
            }
        }

        const fetchCentros = async () => {
            const [data, error] = await fetchResource("/instructor/centros/show", "get", true, null)

            if (data) {
                setLoading(false)
                setCentros(data.centros)
            } else {
                setLoading(false)
                // console.log(error)
            }
        }
        fetchCentros()
        fetchClases()
    }, [])

    const Delete = (data) => {
        setDatoDelete(data)
        setVisibleModal(true)
    }

    const handleDelete = async (id) => {
        let parametro = { id: id }
        const [data, error] = await fetchResource("/instructor/clases/delete", "post", true, parametro)

        if (data) {
            let listaFiltrada = listaClases.filter((item) => item.id !== id)
            setListaClases(listaFiltrada)
            setVisibleModal(false)
        } else {
            // console.log(error)
        }
    }

    const handleCreate = () => {
        setVisible(!visible)
    }

    return (
        <div className="clases-container">
            {visible && (
                <Container title="Crear Clase" more={true}>
                    {centros ? (
                        <CreateClaseHandler
                            centros={centros}
                            setListaClases={setListaClases}
                            listaClases={listaClases}
                        />
                    ) : (
                        <DefaultMessage
                            logo
                            title={"No hay centros registrados"}
                            text={"Debes crear un centro primero para luego poder crear una clase"}
                        />
                    )}
                </Container>
            )}

            <Container title="Clases" more>
                <ClasesFilters create={handleCreate} modo={!visible}></ClasesFilters>

                {loading ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <>
                        {listaClases.length && centros ? (
                            <ClaseTable
                                data={listaClases}
                                centros={centros}
                                handleDelete={(x) => {
                                    Delete(x)
                                }}
                            />
                        ) : (
                            <DefaultMessage title={"Aun no tenes clases registradas"} />
                        )}
                    </>
                )}
            </Container>
            <Modal open={visibleModal}>
                <ModalBody
                    handleDelete={() => {
                        handleDelete(datoDelete.id)
                    }}
                    setModalOpen={(x) => {
                        setVisibleModal(x)
                    }}
                    clase={datoDelete}
                ></ModalBody>
            </Modal>
        </div>
    )
}

export default Clases
