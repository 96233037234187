import { useCallback, useEffect } from "react"
// Estilos
import "./styles/App.css"
// Redux
import { connect } from "react-redux"
import updateUser from "./Store/user/actions"
import updateBasic from "./Store/basicData/actions"
import { selectActiveUser } from "./Store/user/reducer"
import { selectActiveBasicData } from "./Store/basicData/reducer"
// Componentes
import Cookies from "universal-cookie"
import AppLayout from "./Components/Layouts/AppLayout"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import PublicRoutes from "./Components/Routes/PublicRoutes"
import { fetchResource } from "./Functions/Functions"
// Paginas
// import Test from './Pages/Test';
import Home from "./Pages/Home"
import Login from "./Pages/Login"
import PrivateRoutes from "./Components/Routes/PrivateRoutes"
import HomeLayout from "./Components/Layouts/HomeLayout"
import Register from "./Pages/Register"
import NotFound from "./Pages/NotFound"
import AskPasswordReset from "./Pages/AskPasswordReset"
import PasswordResetWithToken from "./Pages/PasswordResetWithToken"
import Profile from "./Pages/Profile"
import Examen from "./Pages/Examen"
import Centros from "./Pages/Centros"
import Clases from "./Pages/Clases"
import Alumnos from "./Pages/Alumnos/Alumnos"
import AlumnosSinInstructor from "./Pages/AlumnosSinInstructor/AlumnosSinInstructor"
import Instructores from "./Pages/Instructores"
import Directores from "./Pages/Directores"
import DirectorInscriptos from "./Pages/DirectorInscriptos"
import Proximamente from "./Pages/Proximamente"
import AlumnosExamen from "./Pages/AlumnosExamen"
import CargaNotas from "./Pages/CargaNotas"
import Matricula from "./Pages/MatriculaUser/Matriculas"
import ControlEvaluacion from "./Pages/Instructor/Evaluacion/ControlEvaluacion.js"
import ClasesAsistencia from "./Pages/Instructor/Asistencia/ClasesAsistencia.js"
import ActvidadesExamenes from "./Pages/ActvidadesExamenes"
import Torneo from "./Pages/Torneo"
import AlumnosTorneo from "./Pages/AlumnosTorneo"
import InscripcionExamen from "./Pages/InscripcionExamen"
import AlumnosExamenInstructor from "./Pages/AlumnosExamenInstructor"
import { EditCategory } from "./Pages/EditCategory"
import ActividadDirector from "./Pages/Directores/Actividades/ActividadDirector"
import MatriculaDirector from "./Pages/Directores/MatriculaDirector"
import InstructorExamen from "./Pages/Instructor/InscripcionExamen.js"
import CuotaRegistroPago from "./Pages/Instructor/Cuotas/CuotaRegistroPago.js"
import Asistencias from "./Pages/Usuarios/Asistencia.js"
import CuotaUserControl from "./Pages/Usuarios/MiCuota/CuotaUserControl.js"
import Libreta from "./Pages/Usuarios/Libreta"
import ListadoInscriptos from "./Pages/Activities/ListadoInscriptos"
import InscriptosActividad from "./Pages/Activities/Inscriptos"
import PrivateRoutesByRole from "./Components/Routes/PrivateRoutesByRole.js"
import HomeExamenes from "./Pages/Instructor/Examenes/HomeExamenes.js"
import ControlUserGym from "./Pages/Instructor/UserGym/ControlUserGym.js"

const App = ({ user, updateUser, basicData, updateBasic }) => {
    // const [ close , setClose ] = useState(false)

    // const asistenciasData = {
    //   titulo:'Asistencia',
    //   texto:'¡Proximamente podra ver sus asistencias a clases!'
    // }
    const pagosData = {
        titulo: "Pagos",
        texto: "¡Proximamente podra ver los pagos de Cuotas, Matriculas y Eventos!",
    }
    // const matriculaData = {
    //   titulo:'Mi Matricula',
    //   texto:'¡Proximamente podras ver el estado de tu matricula!'
    // }
    const cuotaData = {
        titulo: "Mi Cuota",
        texto: "¡Proximamente podras ver el estado de pago de tu clase!",
    }

    const fetchBasicData = useCallback(async () => {
        const [data, error] = await fetchResource("/menu/datos", "get", false, null)

        if (data) {
            updateBasic(data.data)
        } else {
            console.log(error)
        }
    }, [updateBasic])

    useEffect(() => {
        const cookie = new Cookies()

        let logedUser = cookie.get("user")

        if (user.logedIn === false && logedUser) {
            updateUser(logedUser)
        }

        fetchBasicData()
    }, [user, updateUser, fetchBasicData])

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<AppLayout user={user} updateUser={updateUser} />}>
                    <Route path="/" element={<PublicRoutes />}>
                        <Route path="/login" element={<Login updateUser={updateUser} />} />
                        <Route path="/register" element={<Register updateUser={updateUser} />} />
                        <Route path="/passwordresetwithtoken/:token" element={<PasswordResetWithToken />} />
                        <Route path="/askpasswordreset" element={<AskPasswordReset />} />
                        <Route path="*" element={<NotFound />} />
                        {/* <Route index element={<Home />} /> */}
                    </Route>
                    <Route path="/" element={<PrivateRoutesByRole />}>
                        <Route index element={<HomeLayout />} />
                        <Route path="/profile" element={<Profile user={user} updateUser={updateUser} />} />
                        <Route path="/examen" element={<Examen user={user} />} />
                        <Route path="/usuarios/asistencias" element={<Asistencias user={user} />} />
                        <Route path="/usuarios/cuotas" element={<CuotaUserControl user={user} />} />
                        <Route path="/usuarios/libreta" element={<Libreta user={user} />} />
                        <Route path="/usuarios/matricula" element={<Matricula user={user} />} />
                        <Route path="/centros" element={<Centros />} />
                        <Route path="/clases" element={<Clases />} />
                        <Route path="/alumnos" element={<Alumnos />} />
                        <Route path="/alumnosSinInstructor" element={<AlumnosSinInstructor />} />
                        <Route path="/EditCategory/:userId" element={<EditCategory />} />
                        <Route path="/instructor/asistencia" element={<ClasesAsistencia />} />
                        <Route path="/torneo" element={<Torneo />} />
                        <Route path="/alumnosExamen" element={<AlumnosExamen user={user} />} />
                        <Route path="/actividades/inscriptos/:activityId" element={<InscriptosActividad />} />
                        <Route path="/alumnosExamenInstructor/:id" element={<AlumnosExamenInstructor />} />
                        <Route path="/inscripcionExamen" element={<InscripcionExamen />} />
                        <Route path="/instructor/inscripcionExamen" element={<InstructorExamen />} />
                        <Route path="/instructor/HomeExamenes" element={<HomeExamenes />} />
                        <Route path="/instructor/controlUserGym" element={<ControlUserGym />} />
                        <Route path="/alumnosTorneo" element={<AlumnosTorneo user={user} />} />
                        <Route path="/actividadesExamenes" element={<ActvidadesExamenes />} />
                        <Route path="/instructores" element={<Instructores />} />
                        <Route path="/directores" element={<Directores />} />
                        <Route path="/directores/matriculas" element={<MatriculaDirector />} />
                        <Route path="/directores/actividades" element={<ActividadDirector />} />
                        <Route path="/director/inscriptos/:examen_id" element={<DirectorInscriptos user={user} />} />
                        <Route path="/instructor/cuotasRegistro" element={<CuotaRegistroPago />} />
                        <Route path="/director/controlEvaluacion" element={<ControlEvaluacion />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

const mapStateToProps = (state) => {
    return {
        user: selectActiveUser(state),
        basisData: selectActiveBasicData(state),
    }
}

export default connect(mapStateToProps, { updateUser, updateBasic })(App)
