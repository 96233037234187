import React from "react"
import { Button, Checkbox, FormControlLabel, makeStyles, TextField, Typography } from "@material-ui/core"
import Selection from "../Select"
import { useNavigate } from "react-router-dom"

const Styles = makeStyles({
    text: {
        marginBottom: 10,
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
    },
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
    buttonSecondary: {
        width: "210px",
        backgroundColor: "secondary",
    },
    registro: {
        borderTopStyle: "solid",
        borderColor: "#fffb",
        borderWidth: "1px",
        paddingTop: "5px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    forgot: {
        textDecoration: "none",
        color: "#0099ff",
    },
})

const NotasForm = ({ values, handleChange, handleSubmit, notasOpt }) => {
    const style = Styles()
    const navigate = useNavigate()

    return (
        <form onSubmit={handleSubmit} className={style.form}>
            <Selection
                id="nota_1"
                name="nota_1"
                value={values.nota_1}
                options={notasOpt}
                onChange={handleChange}
                label="Actitud Marcial*"
                color="secondary"
                required
            />
            <Selection
                id="nota_2"
                name="nota_2"
                value={values.nota_2}
                options={notasOpt}
                onChange={handleChange}
                label="Teoria*"
                color="secondary"
                required
            />
            <Selection
                id="nota_3"
                name="nota_3"
                value={values.nota_3}
                options={notasOpt}
                onChange={handleChange}
                label="Tul*"
                color="secondary"
                required
            />
            <Selection
                id="nota_4"
                name="nota_4"
                value={values.nota_4}
                options={notasOpt}
                onChange={handleChange}
                label="Desarrollo Tecnico*"
                color="secondary"
                required
            />
            <Selection
                id="nota_5"
                name="nota_5"
                value={values.nota_5}
                options={notasOpt}
                onChange={handleChange}
                label="Media Lucha*"
                color="secondary"
                required
            />
            <Selection
                id="nota_6"
                name="nota_6"
                value={values.nota_6}
                options={notasOpt}
                onChange={handleChange}
                label="Enfrentamiento* Preestablecido"
                color="secondary"
                required
            />
            <Selection
                id="nota_7"
                name="nota_7"
                value={values.nota_7}
                options={notasOpt}
                onChange={handleChange}
                label="Defensa Personal*"
                color="secondary"
                required
            />
            <Selection
                id="nota_8"
                name="nota_8"
                value={values.nota_8}
                options={notasOpt}
                onChange={handleChange}
                label="Coordinacion"
                color="secondary"
            />
            <Selection
                id="nota_9"
                name="nota_9"
                value={values.nota_9}
                options={notasOpt}
                onChange={handleChange}
                label="Bilateralidad"
                color="secondary"
            />
            <Selection
                id="nota_10"
                name="nota_10"
                value={values.nota_10}
                options={notasOpt}
                onChange={handleChange}
                label="Equilibrio"
                color="secondary"
            />
            <Selection
                id="nota_11"
                name="nota_11"
                value={values.nota_11}
                options={notasOpt}
                onChange={handleChange}
                label="Tules Anteriores"
                color="secondary"
            />
            <Selection
                id="nota_12"
                name="nota_12"
                value={values.nota_12}
                options={notasOpt}
                onChange={handleChange}
                label="Lucha"
                color="secondary"
            />
            <Selection
                id="nota_13"
                name="nota_13"
                value={values.nota_13}
                options={notasOpt}
                onChange={handleChange}
                label="Rotura"
                color="secondary"
            />
            <Selection
                id="promedio"
                name="promedio"
                value={values.promedio}
                options={notasOpt}
                onChange={handleChange}
                label="Promedio*"
                color="secondary"
                required
            />
            <FormControlLabel
                control={<Checkbox id="doble" name="doble" checked={values.doble} onChange={handleChange} />}
                label="Doble Graduacíon"
            />
            <Button type="submit" variant="contained" className={style.button} color="primary">
                Registrar Notas
            </Button>
            <Button
                className={style.buttonSecondary}
                onClick={() => navigate(`/director/inscriptos/${values.examen_id}`)}
            >
                Cancelar
            </Button>
        </form>
    )
}

export default NotasForm
