export const UsersTableBasicColumns = [
    { field: "dni", title: "Dni" },
    { field: "nombre", title: "Nombre" },
    { field: "edad", title: "Edad" },
    { field: "telefono", title: "Telefono" },
    { field: "categoria", title: "Categoria" },
    { field: "instructor", title: "Instructor" },
    { field: "escuela", title: "Escuela3" },
]

export const ActivitiesBasicColumns = [
    { field: "fecha", title: "Fecha" },
    { field: "escuela", title: "Escuela" },
    { field: "lugar", title: "Lugar" },
    { field: "descripcion", title: "Descripcion" },
    { field: "inscriptos", title: "Inscriptos" },
    { field: "certificados", title: "Certificados" },
    { field: "inscripcion", title: "Estado Inscripcion" },
    { field: "estado", title: "Estado Evento" },
]

export const InscripctosActivityTableColumns = [
    { field: "fecha", title: "Fecha" },
    { field: "escuela", title: "Escuela" },
    { field: "lugar", title: "Lugar" },
    { field: "descripcion", title: "Descripcion" },
    { field: "inscriptos", title: "Inscriptos" },
    { field: "certificados", title: "Certificados" },
    { field: "inscripcion", title: "Estado Inscripcion" },
    { field: "estado", title: "Estado Evento" },
]

export const UsersTableMatriculas = [
    { field: "dni", title: "Dni" },
    { field: "nombre2", title: "Nombre" },
    { field: "edad", title: "Edad" },
    { field: "categoria_id", title: "Categoria" },
    { field: "instructor_id", title: "Instructor" },
    { field: "escuela_id", title: "Escuela" },
]

export const InscriptosExamenTable = [
    //  {field:'dni',title:'dni'},
    { field: "nombre", title: "Nombre" },
    { field: "edad", title: "Edad" },
    { field: "categoria", title: "Categoria" },
    { field: "instructor", title: "Instructor" },
    { field: "escuela", title: "Escuela" },
]

export const AsistenciaTable = [
    //  {field:'dni',title:'dni'},
    { field: "clase", title: "clase" },
    { field: "fecha", title: "Fecha" },
    { field: "dia", title: "Dia" },
    { field: "estado", title: "Estado" },
]
