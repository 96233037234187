import React from "react"
import { Button, Input, makeStyles, MenuItem, TextField, Typography } from "@material-ui/core"
import Selection from "../Select"

const Styles = makeStyles({
    text: {
        marginBottom: 10,
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
        gap: "1em",
    },
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
    registro: {
        borderTopStyle: "solid",
        borderColor: "#fffb",
        borderWidth: "1px",
        paddingTop: "5px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    forgot: {
        textDecoration: "none",
        color: "#0099ff",
    },
})

const UpdateForm = ({ values, handleChange, handleSubmit, sexo, categorias, loading }) => {
    console.log({ categorias })
    let cat = categorias.filter((x) => values.categoria_id == x.id)
    const style = Styles()

    return (
        <form onSubmit={handleSubmit} className={style.form}>
            <Typography className={style.text}>{`Dni: ${values.dni}`}</Typography>
            <TextField
                id="nombre"
                value={values.nombre}
                onChange={handleChange}
                name="nombre"
                type="text"
                variant="outlined"
                size="small"
                label="Nombre"
                color="secondary"
                required
            />
            <TextField
                id="apellido"
                value={values.apellido}
                onChange={handleChange}
                name="apellido"
                type="text"
                variant="outlined"
                size="small"
                label="Apellido"
                color="secondary"
                required
            />
            <TextField
                id="nacionalidad"
                value={values.nacionalidad}
                onChange={handleChange}
                name="nacionalidad"
                type="text"
                variant="outlined"
                size="small"
                label="Nacionalidad"
                required
                color="secondary"
            />
            <TextField
                id="email"
                value={values.email}
                onChange={handleChange}
                name="email"
                type="email"
                variant="outlined"
                size="small"
                label="Email"
                color="secondary"
                required
            />
            <Selection
                id="sexo"
                name="sexo"
                value={values.sexo}
                options={sexo}
                onChange={handleChange}
                label="Sexo"
                color="secondary"
                required
            />
            <TextField
                id="categoria_id"
                name="categoria_id"
                value={cat[0].nombre}
                label="Categoria"
                variant="outlined"
                size="small"
                color="secondary"
                onlyRead
            />
            <TextField
                id="nacimiento"
                value={values.nacimiento}
                onChange={handleChange}
                label="Fecha Nac."
                name="nacimiento"
                type="date"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                id="telefono"
                value={values.telefono}
                onChange={handleChange}
                name="telefono"
                type="text"
                variant="outlined"
                size="small"
                label="Telefono"
                color="secondary"
                required
            />
            <Button type="submit" variant="contained" className={style.button} disabled={loading} color="primary">
                Actualizar datos
            </Button>
        </form>
    )
}

export default UpdateForm
