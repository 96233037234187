import React, { useState } from "react"
// Componentes
import Header from "../Header/Header"
import Cookies from "universal-cookie"
import { useNavigate, Outlet } from "react-router-dom"
import Hidden from "@material-ui/core/Hidden"
import Drawer from "@material-ui/core/Drawer"
import Toolbar from "@material-ui/core/Toolbar"
import { CssBaseline } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import DrawerItems from "../DrawerItems/DrawerItems"
// import Test from '../../Pages/Test';
import updateUser from "../../Store/user/actions"
import { useSelector, useDispatch } from "react-redux"

const drawerWidth = 240

const styles = makeStyles((theme) => ({
    // container: {
    //     display: 'flex',
    // },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
        // width: drawerWidth,
        // flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: "auto",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
    },
}))

const AppLayout = (props) => {
    const dispatch = useDispatch()

    let navigate = useNavigate()
    // let history = useNavigate()
    const cookie = new Cookies()
    const [mobileOpen, setMobileOpen] = useState(false)
    const handleOpen = () => {
        setMobileOpen(!mobileOpen)
    }

    const logout = () => {
        cookie.remove("user")
        cookie.remove("token")
        dispatch(
            updateUser({
                id: "",
                dni: "",
                nombre: "",
                apellido: "",
                role_id: "",
                foto: "",
                logedIn: false,
            })
        )
        navigate("/")
    }

    const classes = styles()

    return (
        // className={classes.root}
        <div className="app-layout-container">
            <CssBaseline>
                {/* <Header title={process.env.REACT_APP_NAME} handleOpen={handleOpen} updateUser={props.updateUser}/> */}
                <Header title={process.env.REACT_APP_NAME} handleOpen={handleOpen} />
                {props.user.logedIn && (
                    <Hidden xsDown implementation="css">
                        <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }}>
                            <Toolbar />
                            <DrawerItems logOut={logout} user={props.user} />
                        </Drawer>
                    </Hidden>
                )}
                {props.user.logedIn && (
                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor={"left"}
                            open={mobileOpen}
                            onClose={handleOpen}
                            className={classes.drawer}
                            classes={{ paper: classes.drawerPaper }}
                            ModalProps={{ keepMounted: true }}
                        >
                            <Toolbar />
                            <DrawerItems logOut={logout} mobile handleOpen={handleOpen} user={props.user} />
                        </Drawer>
                    </Hidden>
                )}
                <main className={classes.content}>
                    <Toolbar />
                    {/* {props.children} */}
                    <Outlet />
                </main>
            </CssBaseline>
        </div>
    )
}

export default AppLayout
