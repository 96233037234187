import React from "react"
import ContainerTitle from "../ContainerTitle/ContainerTitle"
import AddButton from "../AddButton/AddButton"
import EditButton from "../EditButton/EditButton"

const Containter = ({ title, styles, moreUrl, more, edit, ...props }) => {
    return (
        <div className="container" style={styles}>
            <ContainerTitle text={title} />
            {props.children}
            {!more && <AddButton url={moreUrl} />}
            {edit && <EditButton url={moreUrl} />}
        </div>
    )
}

export default Containter
