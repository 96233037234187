import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import HowToRegIcon from "@material-ui/icons/HowToReg"
import DeleteIcon from "@material-ui/icons/Delete"
import LockIcon from "@material-ui/icons/Lock"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import PrintIcon from "@material-ui/icons/Print"
import { useNavigate } from "react-router-dom"

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    notasOk: {
        color: "#00ff00",
    },
})

const AlumnosExamenTable = ({ data, filtro, setModalOpen, director, examen_id, notas }) => {
    const classes = useStyles()

    const navigate = useNavigate()

    if (filtro) {
        var datos = data.filter((item) => {
            if (
                item.nombre.toLowerCase().substr(0, filtro.length).includes(filtro.toLowerCase()) ||
                item.apellido.toLowerCase().substr(0, filtro.length).includes(filtro.toLowerCase())
            ) {
                return item
            }
        })
    } else {
        var datos = data
    }

    return (
        <TableContainer component={Paper} style={{ marginTop: "30px" }}>
            <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell align="center">Dni</TableCell>
                        <TableCell align="center">Nombre</TableCell>
                        {/* <TableCell align="center">Apellido</TableCell> */}
                        <TableCell align="center">Categoria</TableCell>
                        <TableCell align="center">Email</TableCell>
                        <TableCell align="center">Centro</TableCell>
                        <TableCell align="center">Escuela</TableCell>
                        <TableCell align="center">Instructor</TableCell>
                        <TableCell align="center">Edad</TableCell>
                        {notas && <TableCell align="center">Notas</TableCell>}
                        <TableCell align="center">Remover</TableCell>
                        {/*
                        director && <TableCell align="center">Certificado</TableCell>
                        } */}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {datos.map((fila) => (
                        <TableRow key={fila.id}>
                            <TableCell component="th" scope="row">
                                {fila.id}
                            </TableCell>
                            <TableCell align="center">{fila.dni}</TableCell>
                            <TableCell align="center">{fila.fullName}</TableCell>
                            {/* <TableCell align="center">{fila.apellido}</TableCell> */}
                            {/* <TableCell align="center">{fila.categoria ? fila.categoria.nombre : `No disponible`}</TableCell> */}
                            <TableCell align="center">{fila.categoria ? fila.categoria : `No disponible`}</TableCell>
                            <TableCell align="center">{fila.email ? fila.email : `No disponible`}</TableCell>
                            <TableCell align="center">{fila.centro ? fila.centro.nombre : `No disponible`}</TableCell>
                            <TableCell align="center">{fila.escuela ? fila.escuela : `No disponible`}</TableCell>
                            <TableCell align="center">{fila.instructor ? fila.instructor : `No disponible`}</TableCell>
                            <TableCell align="center">{fila.edad ? fila.edad : `No disponible`}</TableCell>
                            {notas && (
                                <TableCell align="center">
                                    <IconButton
                                        className={fila.promovido ? classes.notasOk : null}
                                        onClick={() => navigate(`/cargaNotas/${fila.id}/${examen_id}`)}
                                    >
                                        <HowToRegIcon />
                                    </IconButton>
                                </TableCell>
                            )}
                            <TableCell align="center">
                                <IconButton onClick={() => setModalOpen(fila.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                            {/*
                                director && <TableCell align="center"><IconButton onClick={()=>{window.open(`http://prod.somacontact.com/certificado.php?examen_id=${examen_id}&user_id=${fila.id}`,'Certificados').focus()}}><PrintIcon/></IconButton></TableCell>
                                } */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default AlumnosExamenTable
