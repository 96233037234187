import React from "react"

const Container = (props) => {
    return (
        <div style={{ maxWidth: "400px" }} className="container">
            <img alt="" src={props.logo} />
            {props.children}
        </div>
    )
}

export default Container
