import React from "react"
import BodyLayout from "../Components/Layouts/BodyLayout"
import Container from "../Components/Formularios/Container"
import Logo from "../Assets/Imagenes/Logo_fit.png"
import RegHandler from "../Components/Formularios/Register/RegHandler"

const Register = (props) => {
    return (
        <BodyLayout>
            <Container logo={Logo}>
                <RegHandler />
            </Container>
        </BodyLayout>
    )
}
export default Register
