import React, { useState, useEffect } from "react"
import Container from "./Container/Container"
import Button from "@material-ui/core/Button"
import { CircularProgress, makeStyles } from "@material-ui/core"
import { fetchResource } from "../Functions/Functions"
import Nota from "../UploadedFiles/Images/examen_nota.jpeg"
import { useNavigate } from "react-router-dom"

const Styles = makeStyles({
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
    buttonDisabled: {
        width: "210px",
        backgroundColor: "#291f21",
    },
})

const TorneoLayout = () => {
    const classes = Styles()
    const navigate = useNavigate()
    // const navigate =useNavigate()

    const [hasInstructor, setHasInstructor] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        const checkInstructor = async () => {
            const [data, error] = await fetchResource("/users/show", "get", true, null)
            if (data) {
                setLoading(false)
                if (data.user.instructor_id) setHasInstructor(true)
            } else {
                setLoading(false)
                console.log(error)
            }
        }
        checkInstructor()
    }, [])

    return (
        <Container more="false" title="Inscripcion a Torneo Fit">
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                    {hasInstructor ? (
                        <Button className={classes.button} onClick={() => navigate("/torneo")}>
                            Inscribirme
                        </Button>
                    ) : (
                        <h4>Consulta con tu instructor</h4>
                    )}
                </>
            )}
        </Container>
    )
}

export default TorneoLayout
