import { IconButton, Tooltip } from "@material-ui/core"
import { ArrowDownward, ArrowUpward } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import EditIcon from "@mui/icons-material/Edit"
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd"
import ClearIcon from "@mui/icons-material/Clear"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import AlumnoDatosPersonales from "./AlumnoDatosPersonales"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
})

const AlumnosTable = (props) => {
    const clases = Styles()

    const {
        Noedita,
        data,
        pageData,
        changePage,
        loading,
        editRecord,
        removeRecord = null,
        addRecord = null,
        instructor,
        info,
        tipoUser,
        actives,
    } = props

    const columns = [
        //  { field:'dni', headerName: 'Dni', width:140 },

        {
            field: "datos",
            headerName: "Info",
            width: 90,
            renderCell: (row) => (
                <IconButton disabled={Noedita} onClick={() => editRecord(row.row.id)}>
                    <Tooltip title="Datos Personales">
                        <EditIcon fontSize="small" />
                    </Tooltip>
                </IconButton>
            ),
        },
        { field: "nombre_completo", headerName: "Nombre", minWidth: 300 },
        {
            field: "edad",
            headerName: "Edad",
            minWidth: 110,
            renderCell: (row) => <div>{row.row.edad || "No Indicado"}</div>,
        },
        // { field:'telefono', headerName: 'Telefono',minWidth: 120, renderCell: row => <div>{row.row.telefono || 'No Indicado'}</div>},
        { field: "categoria", headerName: "Categoria", minWidth: 250 },
        {
            field: "instructor",
            headerName: "Instructor",
            minWidth: 150,
            renderCell: (row) => <div>{row.row.instructor || "No Asignado"}</div>,
        },
        { field: "escuela", headerName: "Escuela", minWidth: 130 },

        {
            field: "actions",
            headerName: "Acciones",
            minWidth: 120,
            renderCell: (row) => {
                if (removeRecord) {
                    return (
                        <IconButton onClick={() => removeRecord(row.row.id)}>
                            <Tooltip title="Remover">
                                <ArrowDownward />
                            </Tooltip>
                        </IconButton>
                    )
                } else if (addRecord) {
                    return (
                        <IconButton onClick={() => addRecord(row.row.id, row.row.nombre_completo)}>
                            <Tooltip title="Añadir">
                                <ArrowUpward />
                            </Tooltip>
                        </IconButton>
                    )
                } else {
                    return (
                        <IconButton disabled onClick={() => addRecord(row.row.id, row.row.nombre_completo)}>
                            <Tooltip title="Añadir">
                                <ArrowUpward />
                            </Tooltip>
                        </IconButton>
                    )
                }
            },
        },
    ]

    return (
        <div className={clases.tableContainer}>
            {data.length ? (
                <DataGrid
                    pagination
                    autoHeight
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={data}
                    pageSize={10}
                    loading={loading}
                    columns={columns}
                    paginationMode="server"
                    isRowSelectable={false}
                    onPageChange={changePage}
                    rowsPerPageOptions={[10]}
                    rowCount={pageData.records}
                    page={pageData.currentPage}
                />
            ) : (
                <div className={clases.noDataContainer}>
                    <h2 className={clases.noDataText}>No se encontraron alumnos</h2>
                </div>
            )}
        </div>
    )
}

export default AlumnosTable
