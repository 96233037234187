import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import CancelRoundedIcon from "@mui/icons-material/CancelRounded"
import { IconButton, Tooltip } from "@material-ui/core"
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded"
import PriceCheckRoundedIcon from "@mui/icons-material/PriceCheckRounded"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        minWidth: "90%",
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
})

const ActividadesExamenesTable = (props) => {
    const clases = Styles()

    const { desvincular, data, changeAsist, handleDelete, estado, pageData, changePage, loading, mensaje } = props

    const columns = [
        {
            field: "id",
            headerName: "Nro",
            minWidth: 50,
            renderCell: (row) => <div style={{ textAlign: "center", width: "100%" }}>{row.row.id}</div>,
        },
        { field: "nombre_completo", headerName: "Nombre y Apellidos", minWidth: 350 },
        { field: "edad", headerName: "Edad", minWidth: 150 },
        { field: "categoria", headerName: "Categoria", minWidth: 200 },
        { field: "instructor", headerName: "Instructor", minWidth: 300 },
        {
            field: "inscr",
            headerName: "Inscripcion",
            width: 160,
            renderCell: (row) => {
                if (1 == 1) {
                    return (
                        <IconButton disabled={estado == 1 ? false : true} onClick={() => {}}>
                            <Tooltip title="Registro de Pago">
                                <AttachMoneyRoundedIcon style={{ borderRadius: 15 }} />
                            </Tooltip>
                        </IconButton>
                    )
                } else {
                    return (
                        <IconButton disabled={estado == 1 ? false : true} onClick={() => {}}>
                            <Tooltip title="Eliminar Pago">
                                <PriceCheckRoundedIcon style={{ color: "green", borderRadius: 15 }} />
                            </Tooltip>
                        </IconButton>
                    )
                }
            },
        },
        {
            field: "quitar",
            headerName: "Eliminar",
            width: 160,
            renderCell: (row) => (
                <IconButton
                    disabled={estado == 1 ? false : true}
                    onClick={() => handleDelete(row.row.id, row.row.nombre_completo)}
                >
                    <Tooltip title="Eliminar Inscripto">
                        <CancelRoundedIcon style={{ borderRadius: 15 }} />
                    </Tooltip>
                </IconButton>
            ),
        },
    ]

    return (
        <div className={clases.tableContainer}>
            {data ? (
                <DataGrid
                    pagination
                    autoHeight
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={data}
                    pageSize={10}
                    loading={loading}
                    columns={columns}
                    onPageChange={changePage}
                    rowsPerPageOptions={[10]}
                    paginationMode="server"
                    isRowSelectable={false}
                    rowCount={pageData.total_records}
                    page={pageData.currentPage}
                    editMode="cell"
                    cell
                />
            ) : (
                <div className={clases.noDataContainer}>
                    <h2 className={clases.noDataText}>{mensaje}</h2>
                </div>
            )}
        </div>
    )
}

export default ActividadesExamenesTable
