import React from "react"
import { AppBar, Button, Toolbar } from "@material-ui/core"
import logo from "../../Assets/Imagenes/Logo_fit.png"
import LinkLogo from "./LinkLogo"
import { useNavigate } from "react-router-dom"
import Cookies from "universal-cookie"
import { connect } from "react-redux"
import { selectActiveUser } from "../../Store/user/reducer"
import CentralMenu from "./CentralMenu"
import MenuOptions from "./MenuOptions"
import { makeStyles } from "@material-ui/core/styles"
import HamburgerMenu from "./HamburgerMenu"
import { Hidden, Typography } from "@material-ui/core"

const styles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
}))

const Header = ({ user, ...props }) => {
    const classes = styles()
    // const history = useHistory()
    const navigate = useNavigate()
    const cookie = new Cookies()

    const logout = () => {
        cookie.remove("user")
        cookie.remove("token")
        props.updateUser({
            id: "",
            dni: "",
            nombre: "",
            apellido: "",
            rol: "",
            logedIn: false,
        })
        navigate("/")
    }

    return (
        <AppBar className={classes.appBar} position="fixed" color="primary">
            <Toolbar>
                <LinkLogo rutaInicial="/" titulo={props.title} logo={logo} />
                <CentralMenu user={user} />
                {/* <Button onClick={()=> props.drawerClose()}>Toogle</Button> */}
                {/* <MenuOptions handleOpen={props.handleOpen} user={user} logout={logout}/> */}
                {user.logedIn ? (
                    <>
                        <Hidden smUp implementation="css">
                            <HamburgerMenu
                                user={user}
                                handleOpen={props.handleOpen}
                                logout={logout}
                                className={classes.menuButton}
                            />
                        </Hidden>
                        <Hidden xsDown implementation="css">
                            <Typography>{`${user.nombre} ${user.apellido}`}</Typography>
                        </Hidden>
                    </>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate("/login")
                        }}
                    >
                        Ingresa
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    )
}

const mapStateToProps = (state) => {
    return {
        user: selectActiveUser(state),
    }
}

export default connect(mapStateToProps)(Header)
