import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        minWidth: "90%",
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
})

const ExamenesTerminadosTable = (props) => {
    const clases = Styles()

    const { desvincular, data, changeAsist, modal, pageData, changePage, loading, mensaje } = props

    console.log({ data })
    const columns = [
        {
            field: "id",
            headerName: "Nro",
            minWidth: 50,
            renderCell: (row) => <div style={{ textAlign: "center", width: "100%" }}>{row.row.id}</div>,
        },
        { field: "nombre_completo", headerName: "Nombre y Apellidos", minWidth: 350 },
        { field: "edad", headerName: "Edad", minWidth: 150 },
        { field: "categoria", headerName: "Categoria", minWidth: 200 },
        { field: "instructor", headerName: "Instructor", minWidth: 300 },
        {
            field: "estado",
            headerName: "Pago",
            width: 160,
            renderCell: (row) => {
                // el control de pago implica checkbox
                if (row.row.estado == 1) {
                    return <Typography style={{ color: "white", fontWeight: "bold" }}>OK</Typography>
                } else if (row.row.estado == 0) {
                    return <Typography style={{ color: "orange", textAlign: "center" }}> Impago </Typography>
                } else {
                    return <Typography style={{ color: "grey", textAlign: "center" }}> S/ Control de Pago </Typography>
                }
            },
        },
    ]

    return (
        <div className={clases.tableContainer}>
            {data ? (
                <DataGrid
                    autoHeight
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={data}
                    pageSize={90}
                    loading={loading}
                    columns={columns}
                    paginationMode="server"
                    isRowSelectable={false}
                    hideFooter
                    hideFooterPagination
                    rowCount={data.length}
                />
            ) : (
                <div className={clases.noDataContainer}>
                    <h2 className={clases.noDataText}>{mensaje}</h2>
                </div>
            )}
        </div>
    )
}

export default ExamenesTerminadosTable
