import React from "react"
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const pageStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "0.8em",
        margin: "1em",
        maxWidth: "90%",
    },
    topFiltersContainer: {
        display: "flex",
        justifyContent: "center",
        gap: "0.8em",
        flexWrap: "wrap",
    },
    bottomFiltersContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "0.8em",
    },
    button: {
        backgroundColor: "red",
    },
})

export const AlumnosDataFilters = (props) => {
    const clases = pageStyles()

    const { params, handleFilter, clearFilters, basicData, userRole, instructor, clasesData } = props

    const { categorias, escuelas, instructores } = basicData

    return (
        <div className={clases.container}>
            <div className={clases.topFiltersContainer}>
                <TextField
                    variant="outlined"
                    size="small"
                    label="Nombre"
                    name="nombre"
                    type="text"
                    value={params.nombre}
                    onChange={handleFilter}
                />
                <TextField
                    variant="outlined"
                    size="small"
                    label="Apellido"
                    name="apellido"
                    type="text"
                    value={params.apellido}
                    onChange={handleFilter}
                />
                <FormControl
                    variant="outlined"
                    style={{ minWidth: 120, marginBottom: 0, paddingBottom: 0 }}
                    size="small"
                >
                    <InputLabel id="categoria" style={{ color: "white" }}>
                        Categoria
                    </InputLabel>
                    <Select
                        label="Categoria"
                        name="categoria"
                        id="categoria"
                        value={params.categoria}
                        variant="outlined"
                        onChange={handleFilter}
                    >
                        <MenuItem value={""}>--</MenuItem>
                        {categorias.map((categoria) => (
                            <MenuItem key={categoria.id} value={categoria.id}>
                                {categoria.nombre}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {clasesData && (
                    <FormControl
                        variant="outlined"
                        style={{ minWidth: 120, marginBottom: 0, paddingBottom: 0 }}
                        size="small"
                    >
                        <InputLabel id="clase" style={{ color: "white" }}>
                            Clase
                        </InputLabel>
                        <Select
                            label="Clase"
                            name="clase"
                            id="clase"
                            value={params.clase}
                            variant="outlined"
                            onChange={handleFilter}
                        >
                            <MenuItem value={""}>--</MenuItem>
                            {clasesData.map((cl) => (
                                <MenuItem key={cl.id} value={cl.id}>
                                    {cl.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </div>
            <div className={clases.bottomFiltersContainer}>
                {userRole <= 2 && instructor == true && (
                    <FormControl
                        variant="outlined"
                        style={{ minWidth: 120, marginBottom: 0, paddingBottom: 0 }}
                        size="small"
                    >
                        <InputLabel id="escuela" style={{ color: "white" }}>
                            Escuela
                        </InputLabel>
                        <Select
                            label="Escuela"
                            name="escuela"
                            id="escuela"
                            value={params.escuela}
                            variant="outlined"
                            onChange={handleFilter}
                        >
                            <MenuItem value={""}>--</MenuItem>
                            {escuelas.map((escuela) => (
                                <MenuItem key={escuela.id} value={escuela.id}>
                                    {escuela.abreviacion}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {userRole <= 2 && instructor == true && (
                    <FormControl
                        variant="outlined"
                        style={{ minWidth: 120, marginBottom: 0, paddingBottom: 0 }}
                        size="small"
                    >
                        <InputLabel id="instructor" style={{ color: "white" }}>
                            Instructor
                        </InputLabel>
                        <Select
                            label="Instructor"
                            name="instructor"
                            id="instructor"
                            value={params.instructor}
                            variant="outlined"
                            onChange={handleFilter}
                        >
                            <MenuItem value={""}>--</MenuItem>
                            {instructores.map((instructor) => (
                                <MenuItem
                                    key={instructor.id}
                                    value={instructor.id}
                                >{`${instructor.nombre} ${instructor.apellido}`}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <TextField
                    InputProps={{ inputProps: { min: 0 } }}
                    variant="outlined"
                    size="small"
                    label="Edad Desde"
                    name="edadDesde"
                    type="number"
                    value={params.edadDesde}
                    onChange={handleFilter}
                />
                <TextField
                    InputProps={{ inputProps: { min: 0 } }}
                    variant="outlined"
                    size="small"
                    label="Edad Hasta"
                    name="edadHasta"
                    type="number"
                    value={params.edadHasta}
                    onChange={handleFilter}
                />
                <Button onClick={clearFilters} className={clases.button}>
                    Limpiar Filtros
                </Button>
            </div>
        </div>
    )
}
