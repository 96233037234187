import { useState, useEffect } from "react"
import Cookies from "universal-cookie"
import store from "../Store"
import updateUser from "../Store/user/actions"

const uCookie = new Cookies()

export const CheckUserLoged = (user) => {
    const cookie = new Cookies()

    let logedUser = cookie.get("user")
    console.log("dentro de funcio", user)

    if (user.logedIn === false && logedUser) {
        return {
            id: logedUser.sub,
            dni: logedUser.dni,
            nombre: logedUser.nombre,
            apellido: logedUser.apellido,
            rol_id: logedUser.rol,
            logedIn: true,
        }
    }
}

export const handleErrors = (response) => {
    //Manejador de Errores.
    if (!response.ok) {
        let errors = {}
        if (response.code === 404 && response.message === "Login incorrecto") {
            errors = {
                ...errors,
                login: true,
            }
        } else {
            if (response.errors.dni) {
                errors = {
                    ...errors,
                    dni: true,
                }
            }
            if (response.errors.email) {
                errors = {
                    ...errors,
                    email: true,
                }
            }
            if (response.errors.nombre) {
                errors = {
                    ...errors,
                    nombre: true,
                }
                // console.log('El nombre solo debe contener caracteres alfanumericos')
            }
            if (response.errors.apellido) {
                errors = {
                    ...errors,
                    apellido: true,
                }
                // console.log('El apellido solo debe contener caracteres alfanumericos')
            }
        }

        throw errors
    }
}

export const fetchResource = async (url, method, auth, data) => {
    const cookie = new Cookies()

    let token = auth ? "Bearer " + cookie.get("token") : ""

    try {
        let config = {
            method: method,
            headers: {
                "Content-type": "application/json",
                Authorization: auth ? token : "",
            },
        }

        let configPost = {
            method: method,
            headers: {
                "Content-type": "application/json",
                Authorization: auth ? token : "",
            },

            body: data ? JSON.stringify(data) : {},
        }

        let res = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + url, data ? configPost : config)

        let response = await res.json()
        // if (response.code === 200 || response.status === 200) {
        if (res.ok) {
            let data = response
            return [data, null]
        } else {
            let error = response
            return [null, error]
        }
    } catch (error) {
        return [null, error]
    }
}

export const useFilter = (data, columns) => {
    const [filter, setFilter] = useState("")
    const [filtered, setFiltered] = useState(data)

    useEffect(() => {
        if (filter) {
            let finalData = filtered.filter((item) => {
                let status = false
                columns.forEach((element) => {
                    let filterPassCondition = !item[element.field]
                        ? ""
                        : item[element.field]
                              .toString()
                              .toLowerCase()
                              .substr(0, filter.length)
                              .includes(filter.toLowerCase())

                    if (filterPassCondition) status = true
                })
                return status
            })
            setFiltered(finalData)
        } else {
            setFiltered(data)
        }
    }, [filter])

    const handleChange = (e) => {
        setFilter(e.target.value)
    }

    return [filter, filtered, handleChange]
}

export const useTableRecordEdit = (editPageUrl) => {
    const handleRecordEdit = (recordId) => {
        console.log(`Editar registro ${recordId} en la Url:${editPageUrl}`)
    }

    return { handleRecordEdit }
}

export const useFirstLastNameFilter = (rawData) => {
    const [filter, setFilter] = useState("")
    const [filteredData, setFilteredData] = useState(rawData)

    const changeFilter = (e) => {
        setFilter(e.target.value)
    }

    const filterRecords = () => {
        setFilteredData(
            rawData.filter((rawRecord) => {
                if (
                    rawRecord.nombre.toLowerCase().includes(filter.toLowerCase()) ||
                    rawRecord.apellido.toLowerCase().includes(filter.toLowerCase())
                ) {
                    return rawRecord
                }
            })
        )
    }

    useEffect(() => {
        filterRecords()
    }, [filter, rawData])

    return [changeFilter, filter, filteredData]
}

export const useCategoryFilter = (rawData) => {
    const [filter, setFilter] = useState("")
    const [filteredData, setFilteredData] = useState(rawData)

    const changeFilter = (e) => {
        setFilter(e.target.value)
    }

    const filterRecords = () => {
        setFilteredData(
            rawData.filter((rawRecord) => {
                if (rawRecord.categoria?.toLowerCase().includes(filter.toLowerCase())) {
                    return rawRecord
                }
            })
        )
    }

    useEffect(() => {
        filterRecords()
    }, [filter, rawData])

    return [changeFilter, filter, filteredData]
}

export const useDataFilter = (rawData, fieldName, type) => {
    const [filter, setFilter] = useState("")
    const [filteredData, setFilteredData] = useState(rawData)

    const changeFilter = (e) => {
        setFilter(e.target.value)
    }

    const filterRecords = () => {
        if (!filter) {
            setFilteredData(rawData)
        } else {
            if (type === "exact") {
                setFilteredData(
                    rawData.filter((rawRecord) => {
                        if (rawRecord[fieldName]?.toString().toLowerCase() === filter.toString().toLowerCase()) {
                            return rawRecord
                        }
                    })
                )
            } else {
                setFilteredData(
                    rawData.filter((rawRecord) => {
                        if (rawRecord[fieldName]?.toString().toLowerCase().includes(filter.toString().toLowerCase())) {
                            return rawRecord
                        }
                    })
                )
            }
        }
    }

    const clearFilter = () => {
        setFilter("")
    }

    useEffect(() => {
        filterRecords()
    }, [filter, rawData])

    return [changeFilter, filter, filteredData, clearFilter]
}

export const useRageFilter = (rawData, fieldName) => {
    const [from, setFrom] = useState(0)
    const [to, setTo] = useState(0)
    const [filteredData, setFilteredData] = useState(rawData)

    const changeFrom = (e) => {
        setFrom(parseInt(e.target.value))
    }

    const changeTo = (e) => {
        setTo(parseInt(e.target.value))
    }

    const filterRecords = () => {
        if (!from && !to) {
            setFilteredData(rawData)
        } else {
            if (from && to) {
                setFilteredData(
                    rawData.filter((rawRecord) => {
                        if (parseInt(rawRecord[fieldName]) >= from && parseInt(rawRecord[fieldName]) <= to)
                            return rawRecord
                    })
                )
            } else {
                if (from) {
                    setFilteredData(
                        rawData.filter((rawRecord) => {
                            if (parseInt(rawRecord[fieldName]) >= from) return rawRecord
                        })
                    )
                } else {
                    setFilteredData(
                        rawData.filter((rawRecord) => {
                            if (parseInt(rawRecord[fieldName]) <= to) return rawRecord
                        })
                    )
                }
            }
        }
    }

    const clearFilter = () => {
        setFrom("")
        setTo("")
    }

    useEffect(() => {
        filterRecords()
    }, [from, to, rawData])

    return [changeFrom, changeTo, from, to, filteredData, clearFilter]
}

export const ageFromBirthDate = (birthdate) => {
    const now = Date.now()
    const bitrh = new Date(birthdate)
    const monthDif = now - bitrh.getTime()
    const date_dt = new Date(monthDif)
    return Math.abs(date_dt.getUTCFullYear() - 1970)
}

export const usePermitions = () => {
    const cookie = new Cookies()
    const { role_id } = cookie.get("user")
    const { id } = cookie.get("user")

    return { role_id: role_id, user_id: id }
}

export const logOutUser = () => {
    const cookie = new Cookies()
    cookie.remove("user")
    cookie.remove("token")
    store.dispatch(
        updateUser({
            id: "",
            dni: "",
            nombre: "",
            apellido: "",
            role_id: "",
            foto: "",
            logedIn: false,
        })
    )
}
