import { FormControl, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core"
import React from "react"

const Styles = makeStyles({
    formControl: {
        minWidth: 255,
        // margin:'8px',
    },
})

const Selection = ({ label, name, value, onChange, options, id, valorNulo = "None" }) => {
    const style = Styles()

    return (
        <FormControl variant="outlined" className={style.formControl} size="small">
            <InputLabel id="select3" color="secondary">
                {label}
            </InputLabel>
            <Select
                name={name}
                labelId="select"
                id="select"
                value={value}
                onChange={onChange}
                label={label}
                color="secondary"
            >
                <MenuItem value={""}>
                    <em>{valorNulo}</em>
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.id} value={id ? option.id : option.nombre}>
                        {option.nombre}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

        // <FormControl variant='outlined' className={style.FormControl}>
        //     <InputLabel id="select">{label}</InputLabel>
        //     <Select
        //         labelId="select"
        //         variant="outlined"
        //         name={name}
        //         value={value}
        //         onChange={onChange}
        //     >
        //         {
        //             options.map(option => (<MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>))
        //         }
        //     </Select>
        // </FormControl>
    )
}

export default Selection
