import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import UpdateForm from "./UpdateForm"
import { fetchResource } from "../../../Functions/Functions"
import Alert from "@material-ui/lab/Alert"
import Typography from "@material-ui/core/Typography"
import updateUser from "../../../Store/user/actions"
import { useSelector, useDispatch } from "react-redux"
import Cookies from "universal-cookie"

const UpdateHandler = ({ fullUser, categorias }) => {
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const cookie = new Cookies()
    const [errors, setErrors] = useState(null)
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)

    const [values, setValues] = useState({
        dni: fullUser.dni,
        nombre: fullUser.nombre,
        apellido: fullUser.apellido,
        nacionalidad: fullUser.nacionalidad,
        sexo: fullUser.sexo,
        email: fullUser.email,
        nacimiento: fullUser.nacimiento ? fullUser.nacimiento : "",
        telefono: fullUser.telefono ? fullUser.telefono : "",
        categoria_id: fullUser.categoria_id ? fullUser.categoria_id : "",
    })

    const sexoOpciones = [
        { id: 1, nombre: "Masculino" },
        { id: 2, nombre: "Femenino" },
    ]

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)

        const [data, error] = await fetchResource("/users/update", "post", true, values)

        if (error) {
            setLoading(false)
            setErrors(error)
        } else {
            cookie.remove("user")
            cookie.set("user", data.user)
            dispatch(
                updateUser({
                    id: data.data.id,
                    dni: data.data.dni,
                    nombre: data.data.nombre,
                    apellido: data.data.apellido,
                    role_id: data.data.role_id,
                    foto: data.data.foto,
                    clase_id: data.data.clase_id,
                    centro_id: data.data.centro_id,
                    escuela_id: data.data.escuela_id,
                    email: data.data.email,
                    instructor_id: data.data.instructor_id,
                    nacimiento: data.data.nacimiento,
                    logedIn: true,
                })
            )
            setLoading(false)
            setSuccess(true)
            setErrors(error)
            // navigate('/login')
        }
    }
    return (
        <div>
            <Typography variant="h6" style={{ textAlign: "center" }}>
                {`${fullUser.nombre} ${fullUser.apellido}`}
            </Typography>
            {errors && (
                <Alert variant="filled" color="error">
                    Verifique los datos ingresados
                </Alert>
            )}
            {success && (
                <Alert variant="filled" color="success">
                    Datos actualizados exitosamente
                </Alert>
            )}
            <UpdateForm
                loading={loading}
                values={values}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                errors={errors}
                sexo={sexoOpciones}
                categorias={categorias}
            />
        </div>
    )
}

export default UpdateHandler
