import { Button, TextField, makeStyles } from "@material-ui/core"
import React from "react"

const Styles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
    },
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
})

const PresetForm = ({ navigate, alert, values, handleChange, handleSubmit, type, name, placehoder, sendButton }) => {
    const style = Styles()

    return (
        <form onSubmit={handleSubmit} className={style.form}>
            <TextField
                id={name}
                value={values}
                onChange={handleChange}
                name={name}
                label={placehoder}
                color="secondary"
                required
                type={type}
                variant="outlined"
                size="small"
            />
            <Button type="submit" variant="contained" className={style.button} color="primary">
                {sendButton}
            </Button>
            {alert && <Button onClick={() => navigate("/login")}>Ingresa</Button>}
        </form>
    )
}

export default PresetForm
