import React from "react"
import { Button, Input, makeStyles, MenuItem, TextField, Typography } from "@material-ui/core"
import Selection from "../Select"

const Styles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
        gap: "1em",
    },
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
    registro: {
        borderTopStyle: "solid",
        borderColor: "#fffb",
        borderWidth: "1px",
        paddingTop: "5px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    forgot: {
        textDecoration: "none",
        color: "#0099ff",
    },
})

const RegForm = ({ values, handleChange, handleSubmit, options, categorias }) => {
    const style = Styles()

    return (
        <form onSubmit={handleSubmit} className={style.form} autoComplete="nope">
            <TextField
                autoComplete="nope"
                id="dni"
                value={values.dni}
                onChange={handleChange}
                name="dni"
                type="text"
                variant="outlined"
                size="small"
                label="Dni"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="nombre"
                value={values.nombre}
                onChange={handleChange}
                name="nombre"
                type="text"
                variant="outlined"
                size="small"
                label="Nombre"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="apellido"
                value={values.apellido}
                onChange={handleChange}
                name="apellido"
                type="text"
                variant="outlined"
                size="small"
                label="Apellido"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="nacionalidad"
                value={values.nacionalidad}
                onChange={handleChange}
                name="nacionalidad"
                type="text"
                variant="outlined"
                size="small"
                label="Nacionalidad"
                required
                color="secondary"
            />
            <TextField
                autoComplete="nope"
                id="email"
                value={values.email}
                onChange={handleChange}
                name="email"
                type="email"
                variant="outlined"
                size="small"
                label="Email"
                color="secondary"
                required
            />
            <Selection
                autoComplete="nope"
                id="sexo"
                name="sexo"
                value={values.sexo}
                options={options}
                onChange={handleChange}
                label="Sexo"
                color="secondary"
                required
            />
            <Selection
                autoComplete="nope"
                id="categoria_id"
                name="categoria_id"
                value={values.categoria_id}
                options={categorias}
                onChange={handleChange}
                label="Categoria"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="nacimiento"
                value={values.nacimiento}
                label="Fecha nac."
                onChange={handleChange}
                name="nacimiento"
                type="date"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="telefono"
                value={values.telefono}
                onChange={handleChange}
                name="telefono"
                type="text"
                variant="outlined"
                size="small"
                label="Telefono"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="password"
                value={values.password}
                onChange={handleChange}
                name="password"
                type="password"
                variant="outlined"
                size="small"
                label="Contraseña"
                color="secondary"
                required
            />
            <Button type="submit" variant="contained" className={style.button} color="primary">
                Registrarme
            </Button>
        </form>
    )
}

export default RegForm

// const RegForm = ({ initialValues , validationSchema , handleSubmit , errors }) => {

//     const style = Styles()

//     if(errors){
//         return (
//             <>
//                 <Alert severity="error" variant="filled">El usuario o contraseña son incorrectos</Alert>
//                 <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
//                     <Form className={style.form}>
//                         <Field type="text" name="dni" id="dni" as={TextField} variant="outlined" label="Dni" size="small" color="secondary"/>
//                         <ErrorMessage name="dni" render={msg => <Typography variant="caption" color="error">{msg}</Typography>}/>
//                         <Field type="password" name="password" id="password" as={TextField} variant="outlined" label="Password" size="small" color="secondary"/>
//                         <ErrorMessage name="password" render={msg => <Typography variant="caption" color="error">{msg}</Typography>}/>
//                         <Button className={style.button} type="submit" variant="contained" color="primary">Ingresar</Button>
//                     </Form>
//                 </Formik>
//                 <div className={style.registro}>
//                     <Button className={style.button} variant="contained" color="primary">Registrate</Button>
//                 </div>
//                     <Link to="/" className={style.forgot}><Typography color="inherit" variant="subtitle1">¿Olvidaste tu contraseña?</Typography></Link>
//             </>
//         )
//     }else{
//         return (
//             <>
//                 <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
//                     <Form className={style.form}>
//                         <Field type="text" name="dni" id="dni" as={TextField} variant="outlined" label="Dni" size="small" color="secondary"/>
//                         <ErrorMessage name="dni" render={msg => <Typography variant="caption" color="error">{msg}</Typography>}/>

//                         <Field type="text" name="nombre" id="nombre" as={TextField} variant="outlined" label="Nombre" size="small" color="secondary"/>
//                         <ErrorMessage name="nombre" render={msg => <Typography variant="caption" color="error">{msg}</Typography>}/>

//                         <Field type="text" name="apellido" id="apellido" as={TextField} variant="outlined" label="Apellido" size="small" color="secondary"/>
//                         <ErrorMessage name="apellido" render={msg => <Typography variant="caption" color="error">{msg}</Typography>}/>

//                         <Field type="text" name="nacionalidad" id="nacionalidad" as={TextField} variant="outlined" label="Nacionalidad" size="small" color="secondary"/>
//                         <ErrorMessage name="nacionalidad" render={msg => <Typography variant="caption" color="error">{msg}</Typography>}/>

//                         <Field type="select" name="sexo" id="sexo" as={Select} variant="outlined" label="Sexo" size="small" color="secondary">
//                             <MenuItem selected>Masculino</MenuItem>
//                             <MenuItem >Femenino</MenuItem>
//                         </Field>
//                         <ErrorMessage name="sexo" render={msg => <Typography variant="caption" color="error">{msg}</Typography>}/>

//                         <Field type="email" name="email" id="email" as={TextField} variant="outlined" label="Email" size="small" color="secondary"/>
//                         <ErrorMessage name="email" render={msg => <Typography variant="caption" color="error">{msg}</Typography>}/>

//                         <Field name="nacimiento" id="nacimiento" as={TextField} type="date" variant="outlined" size="small" color="secondary"/>
//                         <ErrorMessage name="nacimiento" render={msg => <Typography variant="caption" color="error">{msg}</Typography>}/>

//                         <Field type="text" name="telefono" id="telefono" as={TextField} variant="outlined" label="Telefono" size="small" color="secondary"/>
//                         <ErrorMessage name="telefono" render={msg => <Typography variant="caption" color="error">{msg}</Typography>}/>

//                         <Field type="password" name="password" id="password" as={TextField} variant="outlined" label="Password" size="small" color="secondary"/>
//                         <ErrorMessage name="password" render={msg => <Typography variant="caption" color="error">{msg}</Typography>}/>

//                         <Button className={style.button} type="submit" variant="contained" color="primary">Ingresar</Button>
//                     </Form>
//                 </Formik>
//                 <div className={style.registro}>
//                     <Button className={style.button} variant="contained" color="primary">Registrate</Button>
//                 </div>
//                     {/* <Link to="/" className={style.forgot}><Typography color="inherit" variant="subtitle1">¿Olvidaste tu contraseña?</Typography></Link> */}
//             </>
//         )
//     }
// }

// export default RegForm
