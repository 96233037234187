import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import EditIcon from "@mui/icons-material/Edit"
import CheckIcon from "@mui/icons-material/Check"
import CancelIcon from "@mui/icons-material/Clear"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import Tooltip from "@mui/material/Tooltip"
import { Hidden, makeStyles } from "@material-ui/core"
import { red } from "@material-ui/core/colors"
import {
    Cancel,
    CancelOutlined,
    DonutSmallTwoTone,
    GitHub,
    LocationDisabled,
    OpenInNew,
    OutdoorGrill,
    Person,
    PersonAddDisabled,
    PersonAddDisabledSharp,
    PersonOutline,
    PersonOutlineTwoTone,
    PhotoCameraRounded,
    SyncDisabled,
} from "@material-ui/icons"
import { HideGridColMenuItem } from "@mui/x-data-grid"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"

const Styles = makeStyles({
    table: {
        maxWidth: "100%",
    },
    actionCell: {
        display: "flex",
        flexDirection: "row",
    },
})

const UsersTable = ({ data, edit, columns, addAndRemove, add, check }) => {
    const classes = Styles()

    return (
        <TableContainer component={Paper} style={{ marginTop: "30px" }}>
            <Table className={classes.table} aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        {columns.map((rowData) => (
                            <TableCell key={rowData.title} align="center">
                                {rowData.title}
                            </TableCell>
                        ))}
                        {edit && <TableCell align="center">Mas</TableCell>}
                        {addAndRemove && <TableCell align="center">Acciones</TableCell>}
                        {check && <TableCell align="center">Matricula</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((dataReg) => (
                        <TableRow key={dataReg.id}>
                            {columns.map((row) => {
                                if (dataReg[row.field] == "Presente") {
                                    return (
                                        <TableCell key={row.field} align="center" style={{ color: "green" }}>
                                            {"Presente"}
                                        </TableCell>
                                    )
                                } else if (dataReg[row.field] == "Ausente") {
                                    return (
                                        <TableCell key={row.field} align="center" style={{ color: "red" }}>
                                            {"Ausente"}
                                        </TableCell>
                                    )
                                } else {
                                    return (
                                        <TableCell key={row.field} align="center" style={{}}>
                                            {dataReg[row.field] ? dataReg[row.field] : "No Disponible"}
                                        </TableCell>
                                    )
                                }

                                //  return(

                                // <TableCell key={row.field} align="center" style={{}} >

                                //     {dataReg[row.field] == "Presente" ?
                                //         dataReg[row.field] : 'No Disponible'

                                //     }

                                // </TableCell>

                                //  )
                            })}
                            {edit && (
                                <TableCell align="center">
                                    <IconButton onClick={() => edit(dataReg.id)}>
                                        <Tooltip title="Editar/Ver">
                                            <EditIcon fontSize="small" />
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                            )}
                            {check && (
                                <TableCell align="center">
                                    <IconButton onClick={() => check(dataReg.id)}>
                                        {dataReg.value == false ? (
                                            <Tooltip title="registrar">
                                                <CancelIcon sx={{ color: red[500] }} fontSize="medium" />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="borrar registro">
                                                <CheckIcon color="success" fontSize="medium" />
                                            </Tooltip>
                                        )}
                                    </IconButton>
                                </TableCell>
                            )}

                            {addAndRemove && (
                                <TableCell align="center">
                                    <IconButton onClick={() => addAndRemove(dataReg.id)}>
                                        <Tooltip title="ocultar">
                                            <VisibilityOffIcon />
                                        </Tooltip>
                                    </IconButton>

                                    {add ? (
                                        // <IconButton onClick={()=>console.log('Agregar')}>
                                        <IconButton onClick={() => addAndRemove(dataReg.id)}>
                                            <Tooltip title="Añadir">
                                                <ArrowUpwardIcon />
                                            </Tooltip>
                                        </IconButton>
                                    ) : (
                                        // <IconButton onClick={()=>console.log('Sacar')}>
                                        <IconButton onClick={() => addAndRemove(dataReg.id)}>
                                            <Tooltip title="Desvincular">
                                                <ArrowDownwardIcon />
                                            </Tooltip>
                                        </IconButton>
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default UsersTable

{
    /* <MuiThemeProvider theme={theme}>
<MaterialTable 
    icons={tableIcons}
    columns={columns}
    data={data}
    title={tableTitle}
    options={{
        filtering:true,
    }}
/>
</MuiThemeProvider> */
}

// import React , {forwardRef} from 'react'
// import { MuiThemeProvider , createMuiTheme} from '@material-ui/core/styles';
// import MaterialTable from 'material-table'
// import AddBox from '@material-ui/icons/AddBox';
// import ArrowDownward from '@material-ui/icons/ArrowDownward';
// import Check from '@material-ui/icons/Check';
// import ChevronLeft from '@material-ui/icons/ChevronLeft';
// import ChevronRight from '@material-ui/icons/ChevronRight';
// import Clear from '@material-ui/icons/Clear';
// import DeleteOutline from '@material-ui/icons/DeleteOutline';
// import Edit from '@material-ui/icons/Edit';
// import FilterList from '@material-ui/icons/FilterList';
// import FirstPage from '@material-ui/icons/FirstPage';
// import LastPage from '@material-ui/icons/LastPage';
// import Remove from '@material-ui/icons/Remove';
// import SaveAlt from '@material-ui/icons/SaveAlt';
// import Search from '@material-ui/icons/Search';
// import ViewColumn from '@material-ui/icons/ViewColumn';

// const tableIcons = {
//     Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
//     Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
//     Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//     Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
//     DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//     Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
//     Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
//     Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
//     FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
//     LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
//     NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
//     PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
//     ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
//     Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
//     SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
//     ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
//     ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
// };

// const theme = createMuiTheme({
//     palette: {
//         primary: {
//             main: '#0f0f0e',
//         },
//         secondary: {
//             main: '#fff',
//         },
//         error: {
//             main:'#f00',
//         },
//         info: {
//             main: '#494949',
//         },
//         action: {
//             active:'#fff',
//             main: '#000f',
//             hover: '#f00',
//             selected: '#fbb',
//             disabled: '#999',
//         },
//         background: {
//             default: '#fff',
//             paper: '#000d',
//         },
//         divider:'#424242',
//         text: {
//             menu:'#000',
//             primary: '#fff',
//             secondary: '#fff',
//             error: '#f00',
//         }
//     },
// });

// const tipo = ['Nada','Gups','Danes','Mixto']

// if(filtro){
//     var datos = data.filter(item => {
//         if(item.nombre.toLowerCase().substr(0,filtro.length).includes(filtro.toLowerCase()) || item.apellido.toLowerCase().substr(0,filtro.length).includes(filtro.toLowerCase())){
//             return item
//         }
//     })
// }else{
//     var datos = data
// }
// const UsersTable = ({data,move,up,down,handleAdd,handleRemove,filtro}) => {

{
    /* <TableCell component="th" scope="row">
                                    {fila.id}
                                </TableCell> */
}
{
    /* <TableCell align="center">{fila.apellido}</TableCell> */
}
{
    /* <TableCell align="center">{fila.email ? fila.email : `No disponible`}</TableCell> */
}
{
    /* <TableCell align="center">{fila.asociacion && fila.asociacion_id ? fila.asociacion.abreviacion : `No disponible`}</TableCell> */
}
{
    /* {move && <TableCell align="center">{up && <IconButton onClick={()=>handleAdd(fila.id)}><ArrowUpwardIcon/></IconButton>}{down && <IconButton onClick={()=>handleRemove(fila.id)}><ArrowDownwardIcon/></IconButton>}</TableCell>} */
}
{
    /* {move && <TableCell align="center">{up && `Añadir`}{down && `Remover`}</TableCell>} */
}

{
    /* <TableCell>Id</TableCell> */
}

{
    /* <TableCell align="center">Dni</TableCell> */
}
{
    /* <TableCell align="center">Nombre</TableCell> */
}
{
    /* <TableCell align="center">Apellido</TableCell> */
}
{
    /* <TableCell align="center">Categoria</TableCell> */
}
{
    /* <TableCell align="center">Email</TableCell> */
}
{
    /* <TableCell align="center">Escuela</TableCell> */
}
{
    /* <TableCell align="center">Asociacion</TableCell> */
}
{
    /* <TableCell align="center">Telefono</TableCell> */
}
{
    /* <TableCell align="center">Instructor</TableCell> */
}
{
    /* <TableCell align="center">Centro</TableCell> */
}
{
    /* <TableCell align="center">Edad</TableCell> */
}
{
    /* {   
                        datos.map(fila => (
                            <TableRow key={fila.id}>

                                <TableCell align="center">{fila.dni}</TableCell>
                                <TableCell align="center">{fila.nombre}</TableCell>
                                <TableCell align="center">{fila.categoria ? fila.categoria : `No disponible`}</TableCell>
                                <TableCell align="center">{fila.escuela && fila.escuela ? fila.escuela : `No disponible`}</TableCell>
                                <TableCell align="center">{fila.telefono ? fila.telefono : `No disponible`}</TableCell>
                                <TableCell align="center">{fila.instructor ? fila.instructor : `No disponible`}</TableCell>
                                <TableCell align="center">{fila.centro ? fila.centro : `No disponible`}</TableCell>
                                <TableCell align="center">{fila.edad ? fila.edad : `No disponible`}</TableCell>
                                {edit && <TableCell align="center"><IconButton onClick={()=>edit(fila.id)}><EditIcon fontSize="small"/></IconButton></TableCell>}
                            </TableRow>
                        ))
                    } */
}

// import BlockIcon from '@material-ui/icons/Block';
// import DeleteIcon from '@material-ui/icons/Delete';
// import LockIcon from '@material-ui/icons/Lock';
// import LockOpenIcon from '@material-ui/icons/LockOpen';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
// import { RowingSharp } from '@material-ui/icons';
