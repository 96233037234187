import React, { useState, useEffect } from "react"
import Container from "../Components/Container/Container"
import CreateCentroHandler from "../Components/Formularios/Centro/CreateCentroHandler"
import { fetchResource } from "../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import CentroTable from "../Components/Table/CentroTable"
import DefaultMessage from "../Components/DefaultMessage/DefaultMessage"
import ModalBody from "../Components/ModalBody/ModalBody"
import { Modal } from "@material-ui/core"
import { ClasesFilters } from "./ClasesFilters"
import { useNavigate } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"

const Centros = () => {
    const navigate = useNavigate()
    const [listaCentros, setListaCentros] = useState([])
    const [loading, setLoading] = useState(true)
    const [visible, setVisible] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [datoDelete, setDatoDelete] = useState(null)
    const [edit, setEdit] = useState(null)

    useEffect(() => {
        const fetchCentros = async () => {
            const [data, error] = await fetchResource("/instructor/centros/show", "get", true, null)
            if (data) {
                setLoading(false)
                setListaCentros(data.centros)
            } else {
                setLoading(false)
                // console.log(error)
            }
        }
        fetchCentros()
    }, [])

    const handleAccess = (centro) => {
        // navigate('/instructor/controlUserGym',  {state: centro})
    }
    const notify = (x, nombre) => {
        let mensaje = ""

        if (x == 1) {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Correcto."
            }
            toast.success(mensaje, { style: { background: "lightgreen", color: "Black" } })
        } else {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Eliminado"
            }
            toast.error(mensaje, { style: { background: "red", color: "white" } })
        }
    }


    const noVisible = () => {
        setEdit(null)
        setVisible(false)
    }

    const handleEdit = (datos) => {
        let editar = listaCentros.filter((x) => x.id == datos.id)
        setEdit(editar[0])
        setVisible(true)
    }

    const handleDelete = async (id) => {
        let parametro = { id: id }
        const [data, error] = await fetchResource("/instructor/centros/delete", "post", true, parametro)
        if (data) {
            let listaFiltrada = listaCentros.filter((item) => item.id !== id)
            setListaCentros(listaFiltrada)
            setVisibleModal(false)
        } else {
            // console.log(error)
            setVisibleModal(false)
        }
    }

    const handleCreate = () => {
        setVisible(!visible)
    }
    const handleCancel = () => {
        setEdit(null)
        setVisible(false)
    }

    const Delete = (data) => {
        setDatoDelete(data)
        setVisibleModal(true)
    }
    return (
        <div className="centros-container">
              <Toaster position="center-center" />
            {visible && (
                <Container title="Crear Centro" more={true}>
                
                    <CreateCentroHandler
                        setListaCentros={setListaCentros}
                        listaCentros={listaCentros}
                        edit={edit}
                        noVisible={() => noVisible()}
                        handleCancel={() => handleCancel()}
                        not={(x,nom)=>notify(x,nom)}
                    />
                </Container>
            )}

            <Container title="Centros" more={true}>
                <ClasesFilters create={handleCreate} modo={!visible}></ClasesFilters>

                {loading ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <>
                        {listaCentros.length ? (
                            <CentroTable
                                handleEdit={(x) => {
                                    handleEdit(x)
                                }}
                                handleAccess={(x) => {
                                    handleAccess(x)
                                }}
                                data={listaCentros}
                                handleDelete={(x) => {
                                    Delete(x)
                                }}
                            />
                        ) : (
                            <DefaultMessage title={"No hay centros registrados"} />
                        )}
                    </>
                )}
            </Container>

            <Modal open={visibleModal}>
                <ModalBody
                    handleDelete={() => {
                        handleDelete(datoDelete.id)
                    }}
                    setModalOpen={(x) => {
                        setVisibleModal(x)
                    }}
                    centro={datoDelete}
                ></ModalBody>
            </Modal>
        </div>
    )
}

export default Centros
