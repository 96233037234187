import React, { useState } from "react"
import Container from "../../../Components/Container/Container"
import {
    Button,
    makeStyles,
    MenuItem,
    TextField,
    Select,
    InputLabel,
    FormControl,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"

const Styles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
        gap: "1em",
    },
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
    registro: {
        borderTopStyle: "solid",
        borderColor: "#fffb",
        borderWidth: "1px",
        paddingTop: "5px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    forgot: {
        textDecoration: "none",
        color: "#0099ff",
    },
})

const CreaActividad = (props) => {
    const { error, handleSubmit, handleChange, datos, evento, type } = props

    const style = Styles()
    const [check, setCheck] = useState(true)
    const [controlError, setControlError] = useState({
        error_edad_desde: false,
        error_edad_hasta: false,
    })
    const VerificaCambioDesde = (e) => {
        //     const cambio= e.target.value
        console.log("cdcdbio", e)
        //    if (cambio <  evento.edad_hasta){
        //     return handleChange
        //    }else{
        //       setControlError({...controlError,error_edad_desde:true})
        //    }
    }

    return (
        <div>
            <Container title="" more={true}>
                <form onSubmit={handleSubmit} autoComplete="nope" className={style.form}>
                    {error && (
                        <Alert variant="filled" color="error">
                            Verifique los datos ingresados
                        </Alert>
                    )}
                    <TextField
                        autoComplete="nope"
                        id="nombre"
                        name="nombre"
                        label="Nombre"
                        value={evento.nombre}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        required
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        autoComplete="nope"
                        id="fecha"
                        name="fecha"
                        value={evento.fecha}
                        onChange={handleChange}
                        type="date"
                        label="Fecha"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        required
                    />
                    <TextField
                        autoComplete="nope"
                        id="lugar"
                        name="lugar"
                        label="Lugar"
                        value={evento.lugar}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        required
                    />

                    <TextField
                        InputProps={{
                            inputProps: {
                                type: "number",
                                inputmode: "numeric",
                                max: parseInt(evento.edad_hasta) - 1,
                                min: 0,
                            },
                        }}
                        autoComplete="nope"
                        id="edad_desde"
                        name="edad_desde"
                        label="Edad desde"
                        value={evento.edad_desde}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        color="secondary"
                        required
                    />

                    {/* // evento.edad_desde */}
                    <TextField
                        InputProps={{ inputProps: { min: parseInt(evento.edad_desde) + 1, max: 99 } }}
                        autoComplete="nope"
                        id="edad_hasta"
                        name="edad_hasta"
                        label="Edad hasta"
                        value={evento.edad_hasta}
                        onChange={handleChange}
                        type="number"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        required
                    />
                    <FormControl
                        variant="outlined"
                        style={{ minWidth: 120, marginBottom: 0, paddingBottom: 0 }}
                        size="small"
                    >
                        <InputLabel id="categoria_desde" style={{ color: "white" }}>
                            Desde
                        </InputLabel>
                        <Select
                            name="categoria_id_desde"
                            id="categoria_desde"
                            defaultValue={evento.categoria_id_desde}
                            variant="outlined"
                            onChange={handleChange}
                        >
                            <MenuItem value={""}>--</MenuItem>
                            {datos.categorias.map((categoria) => (
                                <MenuItem key={categoria.id} value={categoria.id}>
                                    {categoria.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        variant="outlined"
                        style={{ minWidth: 120, marginBottom: 0, paddingBottom: 0 }}
                        size="small"
                    >
                        <InputLabel id="categoria_hasta" style={{ color: "white" }}>
                            Hasta
                        </InputLabel>
                        <Select
                            name="categoria_id_hasta"
                            id="categoria_hasta"
                            defaultValue={evento.categoria_id_hasta}
                            variant="outlined"
                            onChange={handleChange}
                        >
                            <MenuItem value={""}>--</MenuItem>
                            {datos.categorias.map((categoria) => (
                                <MenuItem key={categoria.id} value={categoria.id}>
                                    {categoria.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={<Checkbox onChange={() => console.log("no  hace nada")} />}
                        label="Control de Cupos"
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputProps: { min: 0 } }}
                        d
                        isabled={check ? false : true}
                        autoComplete="nope"
                        id="cupo_locales"
                        name="cupo_maximo_locales"
                        label="Cupo Inscriptos"
                        value={evento.cupo_maximo_locales}
                        onChange={handleChange}
                        type="number"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        required
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputProps: { min: 0 } }}
                        disabled={check ? false : true}
                        autoComplete="nope"
                        id="cupo_invitados"
                        name="cupo_maximo_invitados"
                        label="Cupo Invitados"
                        value={evento.cupo_maximo_invitados}
                        onChange={handleChange}
                        type="number"
                        variant="outlined"
                        size="small"
                        color="secondary"
                        required
                    />
                    {type == 0 ? (
                        <Button type="submit" variant="contained" className={style.button} color="primary">
                            Crear
                        </Button>
                    ) : (
                        <Button type="submit" variant="contained" className={style.button} color="primary">
                            Modificar
                        </Button>
                    )}
                </form>
            </Container>
        </div>
    )
}

export default CreaActividad
