import { Navigate, Outlet } from "react-router"
import Cookies from "universal-cookie"

const PublicRoutes = () => {
    const cookie = new Cookies()
    console.log(Boolean(cookie.get("user")))
    if (cookie.get("user")) return <Navigate to="/" />

    return (
        <>
            <Outlet />
        </>
    )
}

export default PublicRoutes
