import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import RegForm from "./RegForm"
import { fetchResource } from "../../../Functions/Functions"
import Alert from "@material-ui/lab/Alert"
import CircularProgress from "@material-ui/core/CircularProgress"

const RegHandler = (props) => {
    const navigate = useNavigate()

    const [errors, setErrors] = useState(null)
    const [success, setSuccess] = useState(false)
    const [categorias, setCategorias] = useState(null)
    const [loading, setLoading] = useState(false)

    const [values, setValues] = useState({
        dni: "",
        password: "",
        nombre: "",
        apellido: "",
        nacionalidad: "",
        sexo: "",
        categoria_id: "",
        email: "",
        nacimiento: "",
        telefono: "",
    })

    // Provisorio
    useEffect(() => {
        const fetchCategorias = async () => {
            const [data, error] = await fetchResource("/menu/datos", "get", false, null)

            if (data) {
                setCategorias(data.data.categorias)
            } else {
                console.log(error)
            }
        }
        fetchCategorias()
    }, [])
    // Provisorio

    const sexoOpciones = [
        { id: 1, nombre: "Masculino" },
        { id: 2, nombre: "Femenino" },
    ]

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)

        let fechaSeparada = values.nacimiento.split("-")
        if (fechaSeparada[0].length > 4 || fechaSeparada[1].length > 2 || fechaSeparada[2].length > 2) {
            setErrors(true)
            return
        }

        let phoneCaracters = parseInt(values.telefono) / 10000000000
        if (Math.floor(phoneCaracters) > 0) {
            console.log(true)
            setErrors(true)
            return
        }

        const [data, error] = await fetchResource("/users/register", "post", false, values)

        if (error) {
            setLoading(false)
            setErrors(error)
        } else {
            setLoading(false)
            setSuccess(true)
            setTimeout(() => {
                navigate("/login")
            }, 4000)
        }
    }
    return (
        <>
            {errors?.errors?.dni && (
                <Alert variant="filled" color="error">
                    El Dni ingresado ya existe
                </Alert>
            )}
            {errors && !errors?.errors?.dni && (
                <Alert variant="filled" color="error">
                    Verifique los datos ingresados
                </Alert>
            )}
            {success ? (
                <Alert variant="filled" color="success">
                    Se ha registrado con exito. Redirigiendo al login
                </Alert>
            ) : categorias ? (
                <RegForm
                    values={values}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    errors={errors}
                    options={sexoOpciones}
                    categorias={categorias}
                />
            ) : (
                <CircularProgress />
            )}
        </>
    )
}

export default RegHandler
