import React, { useEffect, useState } from "react"
import Container from "../Components/Container/Container"
import CreateExamHandler from "../Components/Formularios/Examen/CreateExamHandler"
import { fetchResource } from "../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import ExamenTable from "../Components/Table/ExamenTable"
import Modal from "@material-ui/core/Modal"
import ModalBody from "../Components/ModalBody/ModalBody"
import DefaultMessage from "../Components/DefaultMessage/DefaultMessage"
import { useNavigate } from "react-router-dom"

const Examen = () => {
    const navigate = useNavigate()
    const [listaExamenes, setListaExamenes] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchExamenes = async () => {
            const [data, error] = await fetchResource("/director/examen/show", "get", true, null)

            if (data) {
                setLoading(false)
                setListaExamenes(data.examenes)
            } else {
                setLoading(false)
                console.log(error)
            }
        }

        fetchExamenes()
    }, [])

    const handleDelete = async (id, pass) => {
        let parametro = {
            id: id,
            password: pass,
        }
        const [data, error] = await fetchResource("/director/examen/delete", "post", true, parametro)

        if (data) {
            let listaFiltrada = listaExamenes.filter((item) => item.id !== id)
            setListaExamenes(listaFiltrada)
        } else {
            console.log(error)
        }
        setModalOpen(false)
    }

    const handleActive = async (id) => {
        console.log(id)
        let parametro = { id: id }
        const [data, error] = await fetchResource("/director/examen/activation", "post", true, parametro)

        if (data) {
            let listaModif = listaExamenes.map((examen) => {
                if (examen.id === id) {
                    return { ...examen, inscripcion_activa: !examen.inscripcion_activa }
                } else {
                    return { ...examen }
                }
            })
            setListaExamenes(listaModif)
        } else {
            console.log(error)
        }
    }

    const handleEvaluado = async (id) => {
        let parametro = { id: id }
        const [data, error] = await fetchResource("/director/examen/setEvaluado", "post", true, parametro)

        if (data) {
            let listaModif = listaExamenes.map((examen) => {
                if (examen.id === id) {
                    return { ...examen, evaluado: !examen.evaluado }
                } else {
                    return { ...examen }
                }
            })
            setListaExamenes(listaModif)
        } else {
            console.log(error)
        }
    }

    // Modal

    const handleClose = () => {
        setModalOpen(false)
    }

    // Modal

    return (
        <div>
            <Container title="Crear Examen" more={true}>
                <CreateExamHandler setListaExamenes={setListaExamenes} listaExamenes={listaExamenes} />
            </Container>
            <Container title="Examenes" more={true}>
                {loading ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <>
                        {listaExamenes ? (
                            <ExamenTable
                                data={listaExamenes}
                                navigate={navigate}
                                handleEvaluado={handleEvaluado}
                                setModalOpen={setModalOpen}
                                handleActive={handleActive}
                            />
                        ) : (
                            <DefaultMessage title={"No hay examenes registrados"} />
                        )}
                    </>
                )}
                <Modal
                    open={modalOpen}
                    onClose={handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <ModalBody password handleDelete={handleDelete} modalOpen={modalOpen} setModalOpen={setModalOpen} />
                </Modal>
            </Container>
        </div>
    )
}

export default Examen
