import React, { useEffect, useState } from "react"
import AsistenciaLayout from "../AsistenciaLayout"
import DatosLayout from "../DatosLayout"
import MatriculaLayout from "../MatriculaLayout"
import CuotaLayout from "../CuotaLayout"
import ExamenLayout from "../ExamenLayout"
import "./Styles/homelayout.css"
import Grid from "@material-ui/core/Grid"
import { fetchResource, ageFromBirthDate } from "../../Functions/Functions"
import TorneoLayout from "../TorneoLayout"
import ExamenStepLayout from "./ExamenStepLayout"
import ActivityLayout from "../Layouts/ActivityLayout"
import { useSelector } from "react-redux"
import SupercopaIncripcion from "./Supercopa_inscripcion"

const HomeLayout = () => {
    const [autorizado, setAutorizado] = useState(false)
    const [examen, setExamen] = useState(false)
    const [torneos, setTorneos] = useState(false)

    const state = useSelector((state) => ({ ...state["userReducer"] }))

    const { user } = state

    useEffect(() => {
        const checkDataExam = async () => {
            const [data, error] = await fetchResource("/users/examen/availableExams", "get", true)
            if (data) {
                data.data.map((x) => {
                    if (x.inscripcion_activa == 1) {
                        setAutorizado(true)
                    }
                })
            }
        }

        checkDataExam()
    }, [])

    useEffect(() => {
        const fetchExamen = async () => {
            const [data, error] = await fetchResource("/users/examen/showUser", "get", true, null)
            if (data) {
                setExamen(true)
            }
        }
        fetchExamen()
    }, [])

    return (
        <div className="home-layout">
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                    <AsistenciaLayout />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CuotaLayout />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <MatriculaLayout />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <SupercopaIncripcion dni={user.dni} />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <ActivityLayout />
                </Grid>
                {(examen == true) & (autorizado == true) && (
                    <Grid item xs={12} lg={12}>
                        <ExamenStepLayout instructor_id={user.instructor_id} />
                        {/* <TorneoLayout /> */}
                        {
                            //examen?.id &&
                            // <ExamenLayout examen={examen}/>
                        }
                        {/* {<ActivityLayout />} */}
                    </Grid>
                )}

                {/* <Grid item xs={12} lg={12}>
                    {
                        (ageFromBirthDate(user.nacimiento) > 6 && ageFromBirthDate(user.nacimiento) < 12) &&
                        <ActivityLayout />
                    }
                </Grid> */}
                <Grid item xs={12} lg={6}>
                    <DatosLayout />
                </Grid>
            </Grid>
        </div>
    )
}

export default HomeLayout
