import React from "react"
import ReactDOM from "react-dom"
import "./styles/index.css"
import App from "./App"
import { CssBaseline, ThemeProvider } from "@material-ui/core"
import plataformaTkdTheme from "./styles/plataformaTkdTheme"
import store from "./Store"
import { Provider } from "react-redux"

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={plataformaTkdTheme}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
)

// reportWebVitals();
