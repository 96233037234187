import React, { useEffect, useState, useRef } from "react"
import Container from "../Components/Container/Container"
import { fetchResource } from "../Functions/Functions"
import CircularProgress from "@mui/material/CircularProgress"

import { Button, MenuItem, Typography, Select, FormControl, Modal, Input } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import ActividadesExamenesTable from "./ActividadesExamenesTable"
import { useSelector } from "react-redux"
import ModalBody from "../Components/ModalBody/ModalBody"

const Styles = makeStyles({
    actividadesContainer: {
        borderWidth: 1,
        borderRadius: 10,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
    },

    actividadesBox: {
        borderStyle: "solid",
        borderRadius: 10,
        borderWidth: 1,
        width: "100%",
        height: 200,
        textAlign: "center",
        padding: 20,
        margin: 10,
        alignItems: "center",
    },
    examenBox: {
        borderStyle: "solid",
        borderWidth: 1,
        width: "30%",
        height: 200,
        textAlign: "center",
        padding: 20,
        margin: 10,
        alignItems: "center",
    },
    button: {
        minWidth: "210px",
        backgroundColor: "#e00",
        textAlign: "center",
        maxWidth: "220px",
    },
    buttonDisabled: {
        width: "210px",
        backgroundColor: "#291f21",
    },
    eventListContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1em",
        marginTop: "2em",
        borderRadius: 10,
    },
    select: {
        width: 250,
        height: 50,
        fontSize: 25,
        textAlign: "center",
    },
})

const ActvidadesExamenes = () => {
    const state = useSelector((state) => ({ ...state["userReducer"] }))
    const { user } = state
    const classes = Styles()
    const [estado, setEstado] = useState(1)
    const [actividadSelected, setActividadSelected] = useState(0)
    const [actividades, setActividades] = useState(0)
    const [inscriptos, setInscriptos] = useState(0)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(1)
    const [pageData, setPageData] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [pag, setPag] = useState(0)
    const [alumnoBorra, setAlumnoBorra] = useState("")
    const [colorSelect, setColorSelect] = useState("white")
    const listaActividad = useRef(null)

    useEffect(() => {
        fetchActividades()
    }, [estado, status])

    useEffect(() => {
        if (actividadSelected > 0) {
            fetchInscriptosActividad(actividadSelected)
        }
    }, [actividadSelected, pag])

    const fetchActividades = async () => {
        setLoading(true)
        const [data, error] = await fetchResource(`/instructor/actividades/all?status=${status}`, "get", true, null)
        console.log("DDDD ", data)
        if (data) {
            setLoading(false)
            setActividades(data.data)
        } else {
            setLoading(false)
            console.log("ERROR Actividades", error)
            setActividades(0)
        }
    }

    const fetchInscriptosActividad = async (activity_id) => {
        setLoading(true)
        const [data, error] = await fetchResource(
            `/instructor/activity/getUserSigned/${activity_id}?page=${pag + 1}&nombre=&apellido&edadDesde=1&edadHasta=&escuela=&asociacion=&clase=&centro=&categoria=&instructor=${user.id}&dni=`,
            "get",
            true,
            null
        )

        if (data) {
            console.log("EEE ", data)
            setLoading(false)
            setInscriptos(data.data)
            setPageData({ total_records: data.total_records, perPage: data.per_page, currentPage: pag })
        } else {
            setLoading(false)
            console.log("ERROR Inmscriptos", error)
            setInscriptos(0)
        }
    }
    const fetchInscriptosDelete = async (id) => {
        setLoading(true)
        const [data, error] = await fetchResource(
            `/instructor/activity/unsign/${actividadSelected}/${id}`,
            "put",
            true,
            null
        )

        if (data) {
            let alumnos = []
            inscriptos.map((x) => {
                if (x.id == id) {
                    //no hace nada
                } else {
                    alumnos.push({ ...x })
                }
            })
            setInscriptos(alumnos)
            setPag(0)

            setLoading(false)
        } else {
            setLoading(false)
            console.log("ERROR Inmscriptos", error)
        }
    }

    const handleActividad = (x) => {
        setActividadSelected(x)
        fetchInscriptosActividad(x)
    }

    const handleEstado = (x) => {
        setActividadSelected(0)
        setInscriptos(0)
        setEstado(x)
        setStatus(x)
        setPageData({ ...pageData, total_records: "--" })
        setColorSelect("red")
    }

    const handlePage = (page) => {
        setPag(page)
    }

    const handleDelete = (id, nombre) => {
        setAlumnoBorra({ id: id, nombre: nombre })
        setOpenModal(true)

        // fetchInscriptosDelete(id)
    }

    return (
        <div className="instructores-container">
            <Container title="Eventos" more>
                <div className={classes.actividadesContainer}>
                    <div className={classes.actividadesBox}>
                        <div style={{ display: "flex", marginTop: 10 }}>
                            <div style={{ width: "100%" }}>
                                <Typography>Estado: </Typography>
                                {/* <FormControl style={{ marginRight: 20, borderWidth: 1, borderStyle: "solid", borderRadius: 5, padding: 10, width: 280 }}> */}
                                <Select
                                    className={classes.select}
                                    onChange={(x) => handleEstado(x.target.value)}
                                    defaultValue={1}
                                >
                                    <MenuItem id={1} value={1}>
                                        Activas
                                    </MenuItem>
                                    <MenuItem id={3} value={3}>
                                        Terminadas
                                    </MenuItem>
                                </Select>
                                {/* </FormControl> */}
                            </div>
                            <div style={{ width: "100%" }}>
                                <Typography>Seleccione Actividad: </Typography>
                                {/* <FormControl  style={{ borderWidth: 1, borderStyle: "solid", borderRadius: 5, padding: 10, width: 390,borderColor:colorSelect }}> */}

                                <Select
                                    className={classes.select}
                                    style={{ width: 370 }}
                                    onChange={(x) => handleActividad(x.target.value)}
                                    value={actividadSelected}
                                >
                                    <MenuItem disabled id={0} value={0}>
                                        {actividades.length > 0
                                            ? estado == 1
                                                ? "Actividades Activas"
                                                : "Actividades Terminadas"
                                            : "No hay Actividad Registrada."}
                                    </MenuItem>
                                    {actividades.length > 0 &&
                                        actividades.map((x) => {
                                            return (
                                                <MenuItem id={x.id} value={x.id}>
                                                    {x.nombre}
                                                </MenuItem>
                                            )
                                        })}
                                </Select>

                                {/* </FormControl> */}
                            </div>
                            <div style={{ width: "100%", marginTop: 20 }}>
                                <Typography>Total de Inscriptos </Typography>
                                <Typography variant="h4">
                                    {pageData.total_records ? pageData.total_records : "--"}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>

                <ActividadesExamenesTable
                    data={inscriptos}
                    loading={loading}
                    changePage={handlePage}
                    pageData={pageData}
                    estado={estado}
                    handleDelete={handleDelete}
                    mensaje={
                        actividadSelected > 0
                            ? "No hay Alumnos Inscriptos en la Actividad"
                            : " Seleccione una Actividad para ver los Inscriptos"
                    }
                />
            </Container>
            <Modal open={openModal}>
                <ModalBody
                    setModalOpen={(x) => setOpenModal(x)}
                    nombre={alumnoBorra}
                    handleDelete={() => handleDelete()}
                />
            </Modal>
        </div>
    )
}

export default ActvidadesExamenes
