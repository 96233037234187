import { IconButton, Tooltip, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import MoneyOffIcon from "@mui/icons-material/MoneyOff"
import PriceCheckIcon from "@mui/icons-material/PriceCheck"
import moment from "moment/moment"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
})

const CuotaRegistroPagoTable = (props) => {
    const clases = Styles()

    const {
        desvincular,
        data,
        changeAsist,
        modal,
        pageData,
        changePage,
        loading,
        danes,
        tipo,
        maximize,
        pago,
        remove,
        removeRecord = null,
        addRecord = null,
        select,
        tandaSelect,
    } = props

    const columns = [
        {
            field: "sel",
            headerName: "Cuota",
            minWidth: 180,
            renderCell: (row) => {
                if (row.row.estado == true) {
                    return (
                        <IconButton style={{ color: "green", width: "100%" }} onClick={() => remove(row.row.id)}>
                            <PriceCheckIcon fontSize="large" />
                        </IconButton>
                    )
                } else {
                    return (
                        <IconButton style={{ color: "grey", width: "100%" }} onClick={() => pago(row.row.id)}>
                            <Tooltip title="Incluir a tanda">
                                <MoneyOffIcon />
                            </Tooltip>
                        </IconButton>
                    )
                }
            },
        },

        { field: "nombre_completo", headerName: "Nombre", minWidth: 350 },
        { field: "edad", headerName: "Edad", minWidth: 110, renderCell: (row) => <div>{row.row.edad} </div> },
        //  { field:'telefono', headerName: 'Telefono',minWidth: 120, renderCell: row => <div>{row.row.telefono || 'No Indicado'}</div>},
        { field: "categoria", headerName: "Categoria", minWidth: 190 },

        {
            field: "cuota",
            headerName: "Fecha de Pago",
            width: 200,
            renderCell: (row) => {
                if (row.row.fecha !== null) {
                    return (
                        <Typography style={{ color: "white", fontWeight: "bold", textAlign: "center", width: "100%" }}>
                            {moment(row.row.fecha).format("DD-MM-YYYY")}
                        </Typography>
                    )
                } else {
                    return <Typography style={{ color: "grey", textAlign: "center", width: "100%" }}>- -</Typography>
                }
            },
        },
    ]

    return (
        <div className={clases.tableContainer}>
            {data.length ? (
                <DataGrid
                    //  pagination
                    autoHeight={true}
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={data}
                    pageSize={90}
                    loading={loading}
                    columns={columns}
                    paginationMode="server"
                    isRowSelectable={false}
                    //  onPageChange={changePage}
                    // rowsPerPageOptions={[10,20,30]}
                    rowCount={data.length}
                    // page={pageData.currentPage}
                />
            ) : (
                <div className={clases.noDataContainer}>
                    <h2 className={clases.noDataText}>No se encontraron alumnos</h2>
                </div>
            )}
        </div>
    )
}

export default CuotaRegistroPagoTable
