import { IconButton, MenuItem, Select, Tooltip, Typography } from "@material-ui/core"
import { ArrowDownward, ArrowUpward } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import EditIcon from "@mui/icons-material/Edit"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import MoneyOffIcon from "@mui/icons-material/MoneyOff"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import PriceCheckIcon from "@mui/icons-material/PriceCheck"
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
})

const ControlEvaluacionTable = (props) => {
    const clases = Styles()

    const {
        data,
        pageData,
        changePage,
        loading,
        danes,
        tipo,
        maximize,
        handleSelect,
        removeRecord = null,
        addRecord = null,
        select,
        tandaSelect,
    } = props

    const columns = [
        {
            field: "sel",
            headerName: "Tanda",
            minWidth: 100,
            renderCell: (row) => {
                if (row.row.select == true) {
                    return (
                        <IconButton style={{ color: "green" }} onClick={() => tandaSelect(row.row.id, 0, row.row)}>
                            <Tooltip title="Eliminar de Tanda">
                                <CheckCircleOutlineIcon />
                            </Tooltip>
                        </IconButton>
                    )
                } else {
                    return (
                        <IconButton style={{ color: "white" }} onClick={() => tandaSelect(row.row.id, 1, row.row)}>
                            <Tooltip title="Incluir a tanda">
                                <RadioButtonUncheckedIcon />
                            </Tooltip>
                        </IconButton>
                    )
                }
            },
        },

        { field: "nombre_completo", headerName: "Nombre", minWidth: 320 },
        {
            field: "edad",
            headerName: "Edad",
            minWidth: 110,
            renderCell: (row) => <div>{row.row.edad || "No Indicado"}</div>,
        },
        //  { field:'telefono', headerName: 'Telefono',minWidth: 120, renderCell: row => <div>{row.row.telefono || 'No Indicado'}</div>},
        { field: "categoria", headerName: "Categoria Actual", minWidth: 190 },
        {
            field: "nota",
            headerName: "Promedio",
            minWidth: 150,
            renderCell: (row) => {
                if (row.row.promedio) {
                    return <Typography style={{ color: "green" }}>OK</Typography>
                } else {
                    return <Typography style={{ color: "orange" }}>Pendiente</Typography>
                }
            },
        },

        {
            field: "actions",
            headerName: "Evaluador",
            minWidth: 250,
            renderCell: (row) => {
                if (tipo == 0) {
                    return (
                        <Select
                            style={{ width: 300, fontSize: 15 }}
                            defaultValue={0}
                            onChange={handleSelect}
                            option={danes}
                        >
                            <MenuItem disabled value={0}>
                                Seleccione Evaluador
                            </MenuItem>
                            {danes.map((d) => (
                                <MenuItem key={d.id} value={d.id} name={d.nombre}>
                                    {d.nombre}
                                </MenuItem>
                            ))}
                        </Select>
                    )
                } else {
                    return <Typography>s/ Evaluador</Typography>
                }
            },
        },
        {
            field: "observ",
            headerName: "Observ.",
            width: 140,
            renderCell: (row) => {
                if (row.row.pago == true) {
                    return <Typography style={{ color: "lightgreen", fontWeight: "bold" }}>Correcto</Typography>
                } else {
                    return <Typography style={{ color: "orange" }}>Adeuda Pago</Typography>
                }
            },
        },
        { field: "instructor", headerName: "Instructor", minWidth: 200 },
    ]

    return (
        <div className={clases.tableContainer}>
            {data.length ? (
                <DataGrid
                    pagination
                    autoHeight={maximize}
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={data}
                    pageSize={10}
                    loading={loading}
                    columns={columns}
                    paginationMode="server"
                    isRowSelectable={false}
                    onPageChange={changePage}
                    rowsPerPageOptions={[10]}
                    rowCount={pageData.records}
                    page={pageData.currentPage}
                />
            ) : (
                <div className={clases.noDataContainer}>
                    <h2 className={clases.noDataText}>No se encontraron alumnos</h2>
                </div>
            )}
        </div>
    )
}

export default ControlEvaluacionTable
