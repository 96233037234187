import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import Fab from "@material-ui/core/Fab"
import AddIcon from "@material-ui/icons/Add"
import { makeStyles } from "@material-ui/core"
import { useNavigate } from "react-router-dom"

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
const styles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(2),
    },
}))

const MoveButton = ({ direccion, onClick }) => {
    const navigate = useNavigate()
    const classes = styles()

    return (
        <>
            {direccion == "left" ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <Tooltip onClick={() => onClick(0)} title="Anterior" aria-label="add">
                        <Fab color="primary" className={classes.fab}>
                            <ArrowBackIosNewIcon />
                        </Fab>
                    </Tooltip>
                </div>
            ) : direccion == "right" ? (
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <Tooltip onClick={() => onClick(1)} title="Proximo" aria-label="add">
                        <Fab color="primary" className={classes.fab}>
                            <ArrowForwardIosIcon />
                        </Fab>
                    </Tooltip>
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

export default MoveButton
