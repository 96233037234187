import React, { useState, useEffect } from "react"
import Container from "./Container/Container"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core"
import { fetchResource } from "../Functions/Functions"
// import Nota from '../UploadedFiles/Images/examen_nota.jpeg'

const Styles = makeStyles({
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
    buttonDisabled: {
        width: "210px",
        backgroundColor: "#291f21",
    },
})

const ExamenLayout = ({ examen }) => {
    const classes = Styles()

    const [dias, setDias] = useState(0)
    const [registrado, setRegistrado] = useState(false)

    useEffect(() => {
        const checkSigned = async (examen_id) => {
            let parametro = { examen_id: examen_id }
            const [data, error] = await fetchResource("/users/examen/checkSinged", "post", true, parametro)
            if (data) {
                setRegistrado(true)
            } else {
                console.error(error)
            }
        }
        let fechaExamen = examen.fecha.split("-")
        let fecha = new Date()
        let fechaActual = fecha.getDate()
        setDias(fechaExamen[2] - fechaActual)
        checkSigned(examen.id)
    }, [examen])

    const handleInscription = async (id) => {
        let parametro = { examen_id: id }
        const [data, error] = await fetchResource("/users/examen/signToExam", "post", true, parametro)

        if (data) {
            setRegistrado(true)
        } else {
            console.error(error)
        }
    }
    if (registrado) {
        return (
            <Container more="false" title="Inscripcion a Examen">
                <h3
                    style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
                >
                    ¡Ya estas inscripto!
                </h3>
                <h3
                    style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
                >
                    {dias > 1 ? `Faltan ${dias} dias para el examen` : `¡Falta poco!`}
                </h3>
                {/* <Button className={classes.button} onClick={()=>{window.open(Nota,'Curriculum Vitae').focus()}}>Datos del examen</Button> */}
            </Container>
        )
    } else {
        return (
            <Container more="false" title="Inscripcion a Examen">
                {examen.inscripcion_activa ? (
                    <h3
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >{`Inscribite al examen del ${examen.fecha}`}</h3>
                ) : (
                    <h3
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {dias > 1 ? `Faltan ${dias} dias para el examen` : `¡Falta poco para el examen!`}
                    </h3>
                )}
                <Button
                    className={examen.inscripcion_activa ? classes.button : classes.buttonDisabled}
                    onClick={() => {
                        handleInscription(examen.id)
                    }}
                    disabled={!examen.inscripcion_activa}
                >
                    {examen.inscripcion_activa ? "Inscribirme" : "Inscripcion Finalizada"}
                </Button>
            </Container>
        )
    }
}

export default ExamenLayout
