import React, { useEffect, useState } from "react"
import Container from "../../../Components/Container/Container"
import { fetchResource, usePermitions } from "../../../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useSelector } from "react-redux"
import { Button, makeStyles, Select, MenuItem, option, Typography, Tooltip } from "@material-ui/core"
import toast, { Toaster } from "react-hot-toast"
import ControlEvaluacionTable from "./ControlEvaluacionTable"
import ControlEvaluacionDataFilters from "./ControlEvaluacionDataFilters"
import ControlEvaluacionDanesTable from "./ControlEvaluacionDanesTable"
import { useLocation } from "react-router-dom"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import moment from "moment"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import Stack from "@mui/material/Stack"
import Modal from "@material-ui/core/Modal"

const Styles = makeStyles({
    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },
})

const ControlEvaluacion = () => {
    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))
    const { basicData } = state
    const { role_id } = usePermitions()
    const location = useLocation()
    const [tipoSelected, setTipoSelected] = useState(0)
    const [openModal, setOpenModal] = useState(false)
    const [maximizeDatos, setMaximizeDatos] = useState(0)
    const [maximizeInstructor, setMaximizeInstructor] = useState(0)

    const textoMaximize = ["SELECCIONAR EVALUADOR", "MINIMIZAR"]
    const textoMaximizeDatos = ["SELECCIONAR ALUMNOS", "MINIMIZAR"]

    const [evaluadores, setEvaluadores] = useState("")
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [examenes, setExamenes] = useState("") // guarda los id de los examenes activos
    const [alumnos, setAlumnos] = useState("") // guarda los id y nombres de los alumnos
    const [clases, setClases] = useState("") // guarda todas las clases que va a mostrar en Select
    const [examenSelected, setExamenSelected] = useState("") //guarda id de examen seleccionado
    const [estadoExamen, setEstadoExamen] = useState(0)

    const [danes, setDanes] = useState("")

    const [imprimeCertificado, setImprimeCertificado] = useState({ confirmacion: 1, pago: 0 })

    const [tanda, setTanda] = useState([]) //guarda los id de los alumnos inscriptos a la tanda

    const [filtro, setFiltro] = useState("")
    const [records, setRecords] = useState(0)
    const [modal, setModal] = useState(false)
    const paramsInitialValues = {
        page: 0,
        nombre: "",
        apellido: "",
        escuela: "",
        asociacion: "",
        categoria: "",
        instructor: "",
        edadDesde: "",
        edadHasta: "",
        actives: true,
        claseSelected: "",
        rowsPerPage: 10,
    }

    const [params, setParams] = useState(paramsInitialValues)

    useEffect(() => {
        if (alumnos.length > 0) {
            fetchAlumnos()
        }
    }, [params])

    useEffect(() => {
        const fetchEvaluadores = async () => {
            const [data, error] = await fetchResource(`/director/examen/evaluators`, "get", true, null)

            if (data) {
                fetchDanes(data.data)
            } else {
                console.error(error)
            }
        }

        const fetchDanes = async (evaluadores) => {
            const [data, error] = await fetchResource(
                `/instructor/alumnos/show?page=${params.page + 1}&actives=1&nombre=${params.nombre}&apellido=${params.apellido}&asociacion=${params.asociacion}&categoria=${params.categoria}&instructor=${params.instructor}&edadDesde=0&edadHasta=150&perPage=100000`,
                "get",
                true,
                null
            )
            if (data) {
                var danes = []

                data.data.alumnos.map((x) => {
                    if (x.categoria_id >= 11) {
                        if (evaluadores) {
                            var existe = evaluadores.filter((e) => e.id == x.id)
                            if (existe.length) {
                                danes.push({ ...x, nombre_completo: x.nombre + " " + x.apellido, select: true })
                            } else {
                                danes.push({ ...x, nombre_completo: x.nombre + " " + x.apellido, select: false })
                            }
                        } else {
                            danes.push({ ...x, nombre_completo: x.nombre + " " + x.apellido, select: false })
                        }
                    }
                })

                setDanes(danes)
                setEvaluadores(evaluadores)
            } else {
                console.error(error)
            }
        }

        const fetchExamen = async () => {
            const [data, error] = await fetchResource("/director/examen/show", "get", true, null)
            if (data) {
                var exam = []
                var date_create = moment().format("YYYY-MM-DD")
                data.examenes.map((x) => {
                    if (x.evaluado == 0) {
                        if (x.fecha == date_create) {
                            exam.push(x)
                        }
                    }
                })

                setExamenes(exam)
                if (exam.length > 0) {
                    fetchAlumnos(exam[0].id)
                    setExamenSelected(exam[0].id)
                }
            } else {
                console.error(error)
            }
        }

        fetchExamen()
        fetchEvaluadores()
    }, [])

    const fetchAlumnos = async (examSelect) => {
        let es = ""
        if (examSelect) {
            es = examSelect
        } else {
            es = examenSelected
        }

        setLoading(true)
        const [data, error] = await fetchResource(
            `/instructor/examen/showAlumnosForExam?page=${params.page + 1}&clase=${params.claseSelected}&rowsPerPage=${params.rowsPerPage}&nombre=${params.nombre}&edadDesde=${params.edadDesde}&edadHasta=${params.edadHasta}&centro&categoria=${params.categoria}&dni&apellido=${params.apellido} `,
            "GET",
            true,
            null
        )
        if (data) {
            var al = []
            data.data.map((a) => {
                if (a.examen_id == es && a.inscripto == true) {
                    al.push({ ...a, select: false })
                }
            })

            setAlumnos(al)
            setRecords(data.total_records)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const notify = (x, nombre) => {
        let mensaje = ""

        if (x == 1) {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Correcto."
            }
            toast.success(mensaje, { style: { background: "lightgreen", color: "Black" } })
        } else {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Eliminado"
            }
            toast.error(mensaje, { style: { background: "red", color: "white" } })
        }
    }

    /*---------------------------*/

    const handlePageChange = (page1) => {
        setParams({ ...params, page: page1 })
    }

    const handleRemove = async (id) => {
        let parametro = { id: id }
        const [data, error] = await fetchResource("/instructor/alumnos/dettach", "post", true, parametro)

        if (error) console.error(error)
        let alumnosFiltrado = alumnos.filter((data) => data.id !== id)
        setAlumnos(alumnosFiltrado)
    }

    const clearFilters = () => {
        setParams(paramsInitialValues)
    }

    const handleFilter = (e) => {
        const field = e.target.name
        const value = e.target.value
        switch (field) {
            case "nombre":
                setParams({ ...params, nombre: value })
                break
            case "apellido":
                setParams({ ...params, apellido: value })
                break
            case "categoria":
                setParams({ ...params, categoria: value })
                break
            case "escuela":
                setParams({ ...params, escuela: value })
                break
            case "instructor":
                setParams({ ...params, instructor: value })
                break
            case "asociacion":
                setParams({ ...params, asociacion: value })
                break
            case "edadDesde":
                setParams({ ...params, edadDesde: value })
                break
            case "edadHasta":
                setParams({ ...params, edadHasta: value })
                break
            case "actives":
                setParams({ ...params, actives: !params.actives })
                break
            default:
                break
        }
    }

    const select = async (user_id, tipo, datos) => {
        //tipo=0 Borra inscripcion
        //tipo=1 Inscribe

        const body = { evaluators: [user_id] }

        if (tipo == 1) {
            const [data, error] = await fetchResource(`/director/examen/evaluators`, "post", true, body)

            if (data) {
                let DanesActualizado = []
                let evalu = []
                evalu = evaluadores
                evalu.push(datos)
                setEvaluadores(evalu)

                danes.map((d) => {
                    if (d.id == user_id) {
                        DanesActualizado.push({ ...d, select: true })
                    } else {
                        DanesActualizado.push(d)
                    }
                })
                setDanes(DanesActualizado)
                notify(tipo)
            } else {
                alert("error")
            }
        }
        if (tipo == 0) {
            const [data, error] = await fetchResource(`/director/examen/evaluators`, "delete", true, body)

            if (data) {
                let DanesDelete = []
                let evalu = []
                evaluadores.map((e) => {
                    if (e.id !== user_id) {
                        evalu.push(e)
                    }
                })
                setEvaluadores(evalu)
                danes.map((d) => {
                    if (d.id == user_id) {
                        DanesDelete.push({ ...d, select: false })
                    } else {
                        DanesDelete.push(d)
                    }
                })
                setDanes(DanesDelete)

                notify(tipo)
            } else {
                alert("error")
            }
        }
    }

    const tandaSelection = (id, estado, datos) => {
        let sel = []

        tanda.map((x) => {
            if (x.id !== id) {
                sel.push(x)
            }
        })

        if (estado == 1) {
            sel.push(datos)

            console.log(sel)

            setAlumnos(
                alumnos.map((a) => {
                    if (a.id == id) {
                        return { ...a, select: true }
                    } else {
                        return { ...a }
                    }
                })
            )
        } else {
            setAlumnos(
                alumnos.map((a) => {
                    if (a.id == id) {
                        return { ...a, select: false }
                    } else {
                        return { ...a }
                    }
                })
            )
        }

        setTanda(sel)
    }

    const handleSelect = (value) => {
        setExamenSelected(value.target.value)
        setParams({ ...params, page: 0 })
    }

    const handleDelete = async (id) => {
        setModalOpen(false)
    }

    const handleClose = () => {
        setModalOpen(false)
    }

    const MaximizeEvaluadores = () => {
        if (maximizeInstructor == 1) {
            setMaximizeInstructor(0)
        } else {
            setMaximizeInstructor(1)
        }
    }

    const MaximizeDatos = () => {
        if (maximizeDatos == 1) {
            setMaximizeDatos(0)
        } else {
            setMaximizeDatos(1)
        }
    }

    const tipoSelect = (value) => {
        setTipoSelected(value.target.value)
    }

    const handleCertificado = (value) => {
        //tipo=c  es confirmacion
        //tipo= p es pago

        let tipo = value.target.name
        let valor = value.target.checked

        if (tipo == "c" && valor == true) {
            setImprimeCertificado({ ...imprimeCertificado, confirmacion: true })
        }
        if (tipo == "c" && valor == false) {
            setImprimeCertificado({ pago: false, confirmacion: false })
        }

        if (tipo == "p" && valor == true) {
            setImprimeCertificado({ confirmacion: true, pago: true })
        }

        if (tipo == "p" && valor == false) {
            setImprimeCertificado({ ...imprimeCertificado, pago: false })
        }
    }

    const classes = Styles()

    return (
        <div className="alumnos-container">
            <Container title="DIA DE EXAMEN " onClick={() => console.log("sisis")} styles={{ display: "flex" }} more>
                <Toaster position="center-center" />
                {examenes && examenSelected ? (
                    <>
                        {alumnos.length > 0 ? (
                            <>
                                <div
                                    style={{
                                        marginTop: 20,
                                        borderTopLeftRadius: 5,
                                        borderTopRightRadius: 5,
                                        borderStyle: "solid",
                                        borderWidth: 1,
                                        borderColor: "white",
                                        width: "100%",
                                        height: "180px",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "50%",
                                            flexDirection: "column",
                                            display: "flex",
                                            justifyContent: "center",
                                            paddingLeft: "15%",
                                            alignItems: "start",
                                        }}
                                    >
                                        <Typography variant="h3">
                                            {" "}
                                            {moment(examenes[0].fecha).format("DD-MM-YYYY")}
                                        </Typography>
                                        <Typography variant="h5"> {examenes[0].lugar}</Typography>
                                    </div>

                                    <div
                                        style={{
                                            borderStyle: "solid",
                                            borderWidth: 1,
                                            borderTopRightRadius: 5,
                                            borderWidth: 1,
                                            width: "50%",
                                            flexDirection: "column",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "start",
                                        }}
                                    >
                                        <div
                                            style={{
                                                borderWidth: 1,
                                                borderBottomStyle: "solid",
                                                padding: 10,
                                                width: "100%",
                                                flexDirection: "row",
                                                display: "flex",
                                                justifyItems: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Tooltip title="ayuda." placement="top">
                                                <Typography
                                                    style={{
                                                        cursor: "pointer",
                                                        width: "50%",
                                                        textAlign: "center",
                                                        paddingRight: 20,
                                                    }}
                                                >
                                                    {" "}
                                                    Tipo de Seleccion:
                                                </Typography>
                                            </Tooltip>

                                            <Select
                                                disabled={estadoExamen}
                                                style={{ width: "40%", textAlign: "center" }}
                                                defaultValue={0}
                                                onChange={tipoSelect}
                                            >
                                                <MenuItem value={0}>Administrador</MenuItem>
                                                <MenuItem value={1}>Evaluador</MenuItem>
                                            </Select>
                                        </div>

                                        <div
                                            style={{
                                                paddingTop: 20,
                                                width: "100%",
                                                flexDirection: "column",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "start",
                                            }}
                                        >
                                            <Typography
                                                style={{ width: "100%", textAlign: "center", marginBottom: 10 }}
                                            >
                                                Otorga Certificado:
                                            </Typography>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "start",
                                                }}
                                            >
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Typography>Sin Confirmacion</Typography>
                                                    <Switch
                                                        name="c"
                                                        checked={imprimeCertificado.confirmacion}
                                                        onChange={(x) => handleCertificado(x)}
                                                        inputProps={{ "aria-label": "ant design" }}
                                                    />
                                                </Stack>

                                                {/* <Select
                                                disabled={estadoExamen}
                                                style={{ width: "50%", textAlign: "center" }}
                                                value={imprimeCertificado.confirmacion}
                                                onChange={handleCertificado}
                                                name={"c"}

                                            >
                                                <MenuItem value={0}>NO</MenuItem>
                                                <MenuItem value={1}>SI</MenuItem>

                                            </Select> */}
                                            </div>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "start",
                                                }}
                                            >
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Typography>Sin pago</Typography>
                                                    <Switch
                                                        name="p"
                                                        checked={imprimeCertificado.pago}
                                                        onChange={(x) => handleCertificado(x)}
                                                        inputProps={{ "aria-label": "ant design" }}
                                                    />
                                                </Stack>
                                                {/* <Typography style={{ width: "50%", textAlign: "end", paddingRight: 20 }}>Sin Pago:</Typography>
                                            <Select
                                                name={"p"}
                                                disabled={estadoExamen}
                                                style={{ width: "50%", textAlign: "center" }}
                                                value={imprimeCertificado.pago}
                                                onChange={handleCertificado}

                                            >
                                                <MenuItem value={0}>NO</MenuItem>
                                                <MenuItem value={1}>SI</MenuItem>

                                            </Select> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        padding: 10,
                                        background: "green",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderStyle: "solid",
                                        borderWidth: 1,
                                        width: "100%",
                                        borderBottomLeftRadius: 5,
                                        borderBottomRightRadius: 10,
                                    }}
                                >
                                    <Button
                                        style={{ width: "10%", background: "red" }}
                                        onClick={() => setEstadoExamen(!estadoExamen)}
                                    >
                                        {estadoExamen == 0 ? "Configuracion Lista" : " CANCELAR EXAMEN"}
                                    </Button>
                                </div>

                                {estadoExamen == 1 && (
                                    <>
                                        <div
                                            style={{
                                                marginTop: 25,
                                                marginBottom: 10,
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <Typography style={{ width: "50%", textAlign: "center" }}>
                                                Cantidad de Evaluadores:{evaluadores.length}{" "}
                                            </Typography>
                                            <Button
                                                style={{ background: "red", marginLeft: "20%" }}
                                                onClick={() => MaximizeEvaluadores()}
                                            >
                                                {textoMaximize[maximizeInstructor]}
                                            </Button>
                                        </div>

                                        <ControlEvaluacionDanesTable
                                            data={danes}
                                            pageData={{ currentPage: 0, records: danes.length }}
                                            select={(user, tipo, datos) => select(user, tipo, datos)}
                                            maximize={maximizeInstructor}
                                            tipo={tipoSelected}
                                        />
                                        <div style={{ height: 50 }}></div>

                                        <Button
                                            style={{ background: "red", marginBottom: 10 }}
                                            onClick={() => MaximizeDatos()}
                                        >
                                            {textoMaximizeDatos[maximizeDatos]}
                                        </Button>

                                        <ControlEvaluacionTable
                                            data={alumnos}
                                            //  changePage={handlePageChange}
                                            danes={evaluadores}
                                            pageData={{ currentPage: 0, records: danes.length }}
                                            tandaSelect={(id, estado, datos) => tandaSelection(id, estado, datos)}
                                            maximize={maximizeDatos}
                                            tipo={tipoSelected}
                                            //loading={loading}
                                            //pays={Pays}
                                            // removeRecord={handleRemove}
                                            //sign={Sign}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <div className={clases.noDataContainer}>
                                <h2 className={clases.noDataText}>No hay Alumnos registrados al examen.</h2>
                            </div>
                        )}
                    </>
                ) : (
                    <div className={clases.noDataContainer}>
                        <h2 className={clases.noDataText}>No hay un examen registrado para hoy.</h2>
                    </div>
                )}
                <Modal
                    open={openModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div>hola</div>
                    {/* <ModalBody handleDelete={handleDelete} setModalOpen={setModalOpen} modalOpen={modalOpen} password={true} /> */}
                </Modal>
            </Container>
        </div>
    )
}

export default ControlEvaluacion
