import { Navigate, Outlet } from "react-router"
import Cookies from "universal-cookie"
import { logOutUser } from "../../Functions/Functions"
const ALLOWED_DEFAULT_ROLES = [1, 2, 3, 4]

const PrivateRoutesByRole = ({ allowedRoles = ALLOWED_DEFAULT_ROLES }) => {
    const cookie = new Cookies()
    const user = cookie.get("user")
    if (user) {
        if (allowedRoles.includes(user?.role_id)) {
            return (
                <>
                    <Outlet />
                </>
            )
        } else {
            logOutUser()
            return <Navigate to="/login" />
        }
    } else {
        return <Navigate to="/login" />
    }
}

export default PrivateRoutesByRole
