import React, { useState } from "react"
import { Menu, IconButton, Avatar, MenuItem, Typography, Button, makeStyles } from "@material-ui/core"
import PerfilUser from "../../Assets/Imagenes/perfil_default_tkd.png"
import { useNavigate } from "react-router-dom"

const Styles = makeStyles({
    avatar: {
        marginRight: "10px",
        maxWidth: "30px",
        maxHeight: "30px",
    },
})

const UserMenu = ({ user, logout }) => {
    const style = Styles()

    // const navigate =useNavigate()
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    if (user.logedIn) {
        return (
            <>
                <IconButton color="inherit" aria-haspopup="true" onClick={handleClick}>
                    <Avatar className={style.avatar} alt="src" src={PerfilUser} />
                    <Typography>{`${user.nombre} ${user.apellido}`}</Typography>
                </IconButton>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem
                        key={4}
                        onClick={() => {
                            handleClose()
                            navigate("/profilesettings")
                        }}
                    >
                        <IconButton>
                            <Avatar alt="src" src={PerfilUser} />
                        </IconButton>
                    </MenuItem>
                    <MenuItem
                        key={4}
                        onClick={() => {
                            handleClose()
                            logout()
                        }}
                    >
                        <Typography color="menu">Salir 🚪 </Typography>
                    </MenuItem>
                </Menu>
            </>
        )
    } else {
        return (
            <Button variant="contained" color="primary" onClick={() => navigate("/login")}>
                Ingresa
            </Button>
        )
    }
}

export default UserMenu
