import React from "react"
import { Button, makeStyles, TextField, Typography } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { Link, useNavigate } from "react-router-dom"
import { Formik, Form, ErrorMessage, Field } from "formik"

const Styles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1em",
    },
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
    registro: {
        borderTopStyle: "solid",
        borderColor: "#fffb",
        borderWidth: "1px",
        paddingTop: "5px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    forgot: {
        textDecoration: "none",
        color: "#0099ff",
    },
})

const LoginForm = ({ initialValues, validationSchema, handleSubmit, errors }) => {
    const navigate = useNavigate()
    const style = Styles()

    return (
        <>
            {errors && (
                <Alert severity="error" variant="filled">
                    El usuario o contraseña son incorrectos
                </Alert>
            )}
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                <Form className={style.form}>
                    <Field
                        type="text"
                        name="dni"
                        id="dni"
                        as={TextField}
                        variant="outlined"
                        label="Dni"
                        size="small"
                        color="secondary"
                    />
                    <ErrorMessage
                        name="dni"
                        render={(msg) => (
                            <Typography variant="caption" color="error">
                                {msg}
                            </Typography>
                        )}
                    />
                    <Field
                        type="password"
                        name="password"
                        id="password"
                        as={TextField}
                        variant="outlined"
                        label="Password"
                        size="small"
                        color="secondary"
                    />
                    <ErrorMessage
                        name="password"
                        render={(msg) => (
                            <Typography variant="caption" color="error">
                                {msg}
                            </Typography>
                        )}
                    />
                    <Button className={style.button} type="submit" variant="contained" color="primary">
                        Ingresar
                    </Button>
                </Form>
            </Formik>
            <div className={style.registro}>
                <Button
                    onClick={() => navigate("/register")}
                    className={style.button}
                    variant="contained"
                    color="primary"
                >
                    Registrate
                </Button>
            </div>
            <Link to="/askpasswordreset" className={style.forgot}>
                <Typography color="inherit" variant="subtitle1">
                    ¿Olvidaste tu contraseña?
                </Typography>
            </Link>
        </>
    )
}

export default LoginForm

// if(errors){
//     }else{
//         return (
//             <>
//                 <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
//                     <Form className={style.form}>
//                         <Field type="text" name="dni" id="dni" as={TextField} variant="outlined" label="Dni" size="small" color="secondary"/>
//                         <ErrorMessage name="dni" render={msg => <Typography variant="caption" color="error">{msg}</Typography>}/>
//                         <Field type="password" name="password" id="password" as={TextField} variant="outlined" label="Password" size="small" color="secondary"/>
//                         <ErrorMessage name="password" render={msg => <Typography variant="caption" color="error">{msg}</Typography>}/>
//                         <Button className={style.button} type="submit" variant="contained" color="primary">Ingresar</Button>
//                     </Form>
//                 </Formik>
//                 <div className={style.registro}>
//                     <Button onClick={()=>navigate('/register')} className={style.button} variant="contained" color="primary">Registrate</Button>
//                 </div>
//                     {/* <Link to="/" className={style.forgot}><Typography color="inherit" variant="subtitle1">¿Olvidaste tu contraseña?</Typography></Link> */}
//             </>
//         )
//     }
