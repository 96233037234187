import React, { useState, useEffect } from "react"
import Container from "../Container/Container"
import { useSelector } from "react-redux"
import { Button, CircularProgress, makeStyles, Box, Typography, FormControl, InputLabel, Input, FormHelperText, TextField } from "@material-ui/core"
import { fetchResource } from "../../Functions/Functions"
import LogoSupercopa from "../../Assets/Imagenes/Logo_SUPERCOPA.png"
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import Check from "../../Assets/Iconos/green-check.png"
import Cross from "../../Assets/Iconos/red-cross.png"

const Styles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1em",
    },
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
    registro: {
        borderTopStyle: "solid",
        borderColor: "#fffb",
        borderWidth: "1px",
        paddingTop: "5px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    forgot: {
        textDecoration: "none",
        color: "#0099ff",
    },
    typo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginBottom: 40
    },
    img: {
        maxWidth: "50px",
        marginTop: "10px",
        marginBottom: "10px",
    },
    contienelogo: {
        width: "100%",
        height: "200px",
    },
    logo: {
        maxWidth: "200px",
        marginTop: "10px",
    },
})


const SupercopaIncripcion = (props) => {
    const { dni } = props;
    const style = Styles()
    const [datos, setDatos] = useState({
        dni: "",
        altura: 0,
        peso: 0
    })
    const [matricula, setMatricula] = useState(false)
    const [inscripto, setInscripto] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchMatriculas();
    }, [dni])

    // fetchEstadoInscripcion verifica si el dni esta inscripto  la respuesta actualiza el estado inscripto/setInscipto

    const fetchEstadoInscripcion = async () => {
        try {
            let anio = 2024
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/verificaInscripcion/${dni}&${anio}`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },
                })
            let response = await res.json()
            if (response.code == 200) {
                setInscripto(true)
                setLoading(false)
            } else if (response.code == 400) {
                setLoading(false)

            }
        } catch (error) {
            console.log(error)
        }

    }
    const fetchInscripicon = async (peso, altura) => {
        let ligaData = {
            weight: altura,
            height: peso,
            dni: dni,
        }
        const [data, error] = fetchResource('/users/activity/signSp', 'POST', true, ligaData)
        if (data) {
            setInscripto(true)
            console.log(data)
        } else {
            console.error(error)
        }
    }
    // fetchMatriculas actualiza el estado  matriculade la matricula este creo q no hay que tocarlo
    // fijate que hay fetch anidados para que no tire errores de carga lo hice

    const fetchMatriculas = async () => {
        setLoading(true)
        const [data, error] = await fetchResource("/users/matriculas/show", "get", true, null)
        if (data) {
            setMatricula(data.data.actual)
            setLoading(false)
        } else {
            console.error(error)
            setLoading(false)
        }
        fetchEstadoInscripcion();
    }

    const SignupSchema = Yup.object().shape({
        altura: Yup.number()
            .min(10, 'ALTURA NO VALIDA')
            .max(250, 'ALTURA NO VALIDA')
            .required('REQUERIDO'),
        peso: Yup.number()
            .min(10, 'PESO NO PERMITIDO!!')
            .required('REQUERIDO'),
    });

    return (
        <Container more styles={{ display: "block", textAlign: "center" }} title={"INSCRIPCION LIGA SUPER COPA TKD "}>
            <div className={style.contienelogo}  >
                <img className={style.logo} src={LogoSupercopa} />
            </div>
            {loading ? <CircularProgress />
                :
                <>
                    <Typography variant="h6" aria-label="matricula" className={style.typo}>
                        {inscripto && "YA ESTAS INSCRIPTO!!"}
                        {matricula == false && ` Matricula Anual Impaga`}
                        {matricula && inscripto == false && `Matricula Anual `}
                        <img className={style.img} src={matricula ? Check : Cross} />
                        {matricula && inscripto == false && `PUEDES INSCRIBIRTE. `}
                        {matricula == false && inscripto == false && `PUEDE INSCRIBIRSE, luego `}
                        {matricula ? "" : `contactese con su instructor para completar la Inscripcion.`}

                    </Typography>

                    <Formik initialValues={datos} validationSchema={SignupSchema} onSubmit={(values) => fetchInscripicon(values.peso, values.altura)}>

                        <Form className={style.form}>
                            <Field
                                type="number"
                                name="peso"
                                id="peso"
                                as={TextField}
                                variant="outlined"
                                label="Peso (Kg.)"
                                size="small"
                                color="secondary"

                            />
                            <ErrorMessage
                                name="peso"
                                render={(msg) => (
                                    <Typography variant="caption" color="error">
                                        {msg}
                                    </Typography>
                                )}
                            />
                            <Field
                                type="number"
                                name="altura"
                                id="altura"
                                as={TextField}
                                variant="outlined"
                                label="Altura (cm,sin puntos ni comas)"
                                size="small"
                                color="secondary"
                            />
                            <ErrorMessage
                                name="altura"
                                render={(msg) => (
                                    <Typography variant="caption" color="error">
                                        {msg}
                                    </Typography>
                                )}
                            />
                            <Button className={style.button} type="submit" variant="contained" color="primary">
                                {inscripto ? "Actualizar Datos " : "Inscribirse"}
                            </Button>
                        </Form>
                    </Formik>
                </>
            }
        </Container>
    )
}

export default SupercopaIncripcion
