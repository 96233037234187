import React, { useState } from "react"
import { handleErrors } from "../../../Functions/Functions"
import Alert from "@material-ui/lab/Alert"
import PresetForm from "../PasswordReset/PresetForm"

const AskResetHandler = () => {
    const [alert, setAlert] = useState(false)
    const [errors, setErrors] = useState(false)
    const [datos, setDatos] = useState({
        dni: "",
    })

    const handleChange = (e) => {
        setDatos({ dni: e.target.value })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()

        try {
            let config = {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(datos),
            }

            let res = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + "/users/askPasswordReset", config)
            let response = await res.json()

            if (response.code === 200) {
                console.log(response)
                setAlert(true)
                setErrors(false)
                setDatos({ dni: "" })
            } else {
                setErrors(true)
                setAlert(false)
                setDatos({ dni: "" })

                // handleErrors(response)
                console.log(response)
            }
        } catch (error) {
            console.log("errores", error)
            setErrors(error)
        }
    }

    return (
        <>
            {alert && (
                <Alert variant="filled" color="success">
                    ¡Enviamos un mail a su cuenta registrada!
                </Alert>
            )}
            {errors && (
                <Alert variant="filled" color="error">
                    Ocurrio un error
                </Alert>
            )}
            <PresetForm
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                values={datos.dni}
                type="text"
                name="dni"
                placehoder="Dni"
                sendButton="Enviar"
            />
        </>
    )
}

export default AskResetHandler
