import { makeStyles } from "@material-ui/styles"
import React from "react"

const styles = makeStyles({
    container: {
        minWidth: "90%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "1em",
    },
    recordsCount: {
        fontSize: 18,
        textAlign: "center",
        padding: "0.6em",
        // Border
        border: "solid 2px white",
        borderRadius: "10px 5px",
    },
    activeSection: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0.6em",
        // Border
        border: "solid 2px white",
        borderRadius: "10px 5px",
    },
    switchContainer: {},
    switch: {},
    activesFlag: {
        minWidth: "5em",
        textAlign: "center",
    },
})

export const InscriptosTopInformation = ({ records }) => {
    const clases = styles()

    return (
        <div className={clases.container}>
            <div className={clases.recordsCount}>Cantidad de Alumnos inscriptos: {records}</div>
        </div>
    )
}
