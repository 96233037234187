import React, { useState } from "react"
import { fetchResource, usePermitions } from "../../../Functions/Functions"
import { useSelector } from "react-redux"
import { Button, makeStyles, Typography } from "@material-ui/core"
import toast from "react-hot-toast"
import { useLocation } from "react-router-dom"
import moment from "moment"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import "dayjs/locale/es"

const Styles = makeStyles({
    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },
    fecha: {
        width: 300,
        height: 80,
        margin: 10,
        marginTop: 10,
        justifyContent: "center",
        "& .MuiSvgIcon-root": {
            color: "white",
            fontSize: 40,
        },
        "& .MuiInputBase-root": {
            fontSize: 40,
            color: "white",
        },
    },

    top: {
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 5,
        marginBottom: 20,
        marginTop: 10,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 10,
        "@media (min-width: 1200px)": { flexDirection: "row" },
    },
})

const ClasesAsistenciaTopInfo = (props) => {
    const { btnvolver, permitido, btnalumnos, datos, fecha, nombreClase, changeFecha, config } = props

    const { role_id } = usePermitions()
    const location = useLocation()
    const examenesActivos = location.state
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [alumnos, setAlumnos] = useState([]) // guarda los id y nombres de los alumnos
    const [clases, setClases] = useState("") // guarda todas las clases que va a mostrar en Select
    const [records, setRecords] = useState(0)
    const [modal, setModal] = useState(false)

    //   const [dia, setDia] = useState(moment().format("YYYY-MM-DD"))

    const paramsInitialValues = {
        page: 0,

        nombre: "",
        apellido: "",
        escuela: "",
        asociacion: "",
        categoria: "",
        instructor: "",
        edadDesde: "",
        edadHasta: "",
        actives: true,
        claseSelected: "",
        rowsPerPage: 10,
    }
    const [params, setParams] = useState(paramsInitialValues)

    const fetchClases = async () => {
        const [data, error] = await fetchResource(`/menu/clases`, "GET", true, null)

        if (data) {
            setLoading(false)
            setClases(data.data)
        } else {
            setLoading(false)
            // console.log(error)
        }
    }

    const notify = (x, nombre) => {
        let mensaje = ""

        if (x == 1) {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Correcto."
            }
            toast.success(mensaje, { style: { background: "lightgreen", color: "Black" } })
        } else {
            if (nombre) {
                mensaje = nombre
            } else {
                mensaje = "Registro Eliminado"
            }
            toast.error(mensaje, { style: { background: "red", color: "white" } })
        }
    }

    /*---------------------------*/
    const conteo = (tipo) => {
        //tipo=0 ausentes ,tipo=1 presentes
        let c = 0

        let presente = " --"
        let ausente = " --"

        if (datos !== "" && permitido == true) {
            presente = 0
            ausente = 0

            datos.map((x) => {
                if (x.estado == 1) {
                    presente = presente + 1
                } else {
                    ausente = ausente + 1
                }
            })
        }

        if (tipo == 1) {
            c = presente
        } else {
            c = ausente
        }

        return c
    }

    const handlePageChange = (page1) => {
        setParams({ ...params, page: page1 })
    }

    const handleRemove = async (id) => {
        let parametro = { id: id }
        const [data, error] = await fetchResource("/instructor/alumnos/dettach", "post", true, parametro)

        if (error) console.error(error)
        let alumnosFiltrado = alumnos.filter((data) => data.id !== id)
        setAlumnos(alumnosFiltrado)
    }

    const clearFilters = () => {
        setParams(paramsInitialValues)
    }

    const fechaPalabra = () => {
        let dp = moment(fecha).day()
        let palabra = ""
        switch (dp) {
            case 0:
                palabra = "Domingo"
                break
            case 1:
                palabra = "Lunes"
                break
            case 2:
                palabra = "Martes"
                break
            case 3:
                palabra = "Miercoles"
                break
            case 4:
                palabra = "Jueves"
                break
            case 5:
                palabra = "Viernes"
                break
            case 6:
                palabra = "Sabado"
                break

            default:
                break
        }
        return palabra
    }

    const classes = Styles()

    return (
        <div className={classes.top}>
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                <Typography variant="h6" style={{ width: "100%", textAlign: "center" }}>
                    {nombreClase.toUpperCase()}
                </Typography>

                <div style={{ width: "100%", textAlign: "center" }}>
                    <div style={{ width: "100%", textAlign: "center", display: "flex", flexDirection: "row" }}>
                        <div
                            style={{
                                padding: 5,
                                borderTopLeftRadius: 5,
                                borderStyle: "solid",
                                borderWidth: 1,
                                width: "50%",
                                textAlign: "center",
                            }}
                        >
                            <Typography style={{ fontSize: 15, width: "100%", textAlign: "center", color: "green" }}>
                                PRESENTES
                            </Typography>
                            <Typography style={{ fontSize: 25, width: "100%", textAlign: "center" }}>
                               
                                {conteo(1)}
                            </Typography>
                        </div>

                        <div
                            style={{
                                padding: 5,
                                borderTopRightRadius: 5,
                                borderStyle: "solid",
                                borderWidth: 1,
                                width: "50%",
                                textAlign: "center",
                            }}
                        >
                            <Typography style={{ fontSize: 15, width: "100%", textAlign: "center", color: "red" }}>
                                AUSENTES 
                            </Typography>
                            <Typography style={{ fontSize: 25, width: "100%", textAlign: "center" }}>
                               
                                {conteo(0)}
                            </Typography>
                        </div>
                    </div>
                    <div
                        style={{
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderBottomLeftRadius: 5,
                            borderBottomRightRadius: 5,
                            width: "100%",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <Typography style={{ width: "50%", textAlign: "end", margin: 5 }}>TOTAL </Typography>
                        <Typography style={{ width: "50%", textAlign: "initial", margin: 5 }}>
                         
                            {datos.length}
                        </Typography>
                    </div>
                </div>
            </div>

            <div
                style={{
                    marginTop: 20,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography variant="h4">{fechaPalabra().toUpperCase()}</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"}>
                    <DatePicker
                        format="DD-MM-YYYY"
                        className={classes.fecha}
                        onChange={(d) =>
                            changeFecha(
                                `${d.$y}-${d.$M + 1 <= 9 ? "0" + (d.$M + 1) : d.$M + 1}-${d.$D <= 9 ? "0" + d.$D : d.$D}`
                            )
                        }
                        value={dayjs(fecha)}
                        minDate={dayjs(config.inicio)}
                        maxDate={dayjs(config.fin)}
                        disableFuture
                    />
                </LocalizationProvider>
            </div>

            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button onClick={() => btnalumnos()} style={{ background: "red", margin: 10, width: 100 }}>
                    Alumnos
                </Button>
                <Button onClick={() => btnvolver()} style={{ background: "red", margin: 10, width: 100 }}>
                    Volver
                </Button>
            </div>
        </div>
    )
}

export default ClasesAsistenciaTopInfo
