import { Switch } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/styles"
import React from "react"

const styles = makeStyles({
    container: {
        minWidth: "90%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "1em",
    },
    recordsCount: {
        fontSize: 18,
        textAlign: "center",
        padding: "0.6em",
        // Border
        border: "solid 2px white",
        borderRadius: "10px 5px",
    },
    activeSection: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0.6em",
        // Border
        border: "solid 2px white",
        borderRadius: "10px 5px",
    },
    switchContainer: {},
    switch: {},
    activesFlag: {
        minWidth: "5em",
        textAlign: "center",
    },
})

export const AlumnosTopInformation = ({ records, handleFilter, params, activesSwitch }) => {
    const clases = styles()
    const theme = useTheme()

    return (
        <div className={clases.container}>
            <div className={clases.recordsCount}>Cantidad de Alumnos: {records}</div>
            <div></div>
            {activesSwitch && (
                <div className={clases.activeSection}>
                    <div>Tipo de usuarios:</div>
                    <Switch className={clases.switch} onChange={handleFilter} checked={params.actives} name="actives" />
                    <div className={clases.activesFlag}>
                        {params.actives ? (
                            <div style={{ color: theme.palette.success.main }}>Activos</div>
                        ) : (
                            <div style={{ color: theme.palette.error.main }}>Inactivos</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
