import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import Fab from "@material-ui/core/Fab"
import EditIcon from "@material-ui/icons/Edit"
import { makeStyles } from "@material-ui/core"
import { useNavigate } from "react-router-dom"

const styles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(2),
    },
}))

const EditButton = ({ url }) => {
    const navigate = useNavigate()
    const classes = styles()

    return (
        <Tooltip onClick={() => navigate(url)} title="Más" aria-label="add">
            <Fab color="primary" className={classes.fab}>
                <EditIcon />
            </Fab>
        </Tooltip>
    )
}

export default EditButton
