import { IconButton, Tooltip } from "@material-ui/core"
import { ArrowDownward, ArrowUpward } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import EditIcon from "@mui/icons-material/Edit"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import MoneyOffIcon from "@mui/icons-material/MoneyOff"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import PriceCheckIcon from "@mui/icons-material/PriceCheck"
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"

const Styles = makeStyles({
    tableContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    noDataContainer: {
        border: "solid 1px white",
        padding: "0.2em 1em 0.2em 1em",
        borderRadius: "5px",
        minWidth: "90%",
    },
    noDataText: {
        textAlign: "center",
    },
})

const ControlEvaluacionDanesTable = (props) => {
    const clases = Styles()

    const { data, pageData, maximize, loading, select, tandaSelect, handleCertificado } = props

    const columns = [
        {
            field: "more",
            headerName: "sel.",
            width: 100,
            renderCell: (row) => {
                if (row.row.select == true) {
                    return (
                        <IconButton style={{ color: "green" }} onClick={() => select(row.row.id, 0, row.row)}>
                            <Tooltip title="Sumar a Tanda">
                                <CheckCircleOutlineIcon />
                            </Tooltip>
                        </IconButton>
                    )
                } else {
                    return (
                        <IconButton style={{ color: "gray" }} onClick={() => select(row.row.id, 1, row.row)}>
                            <Tooltip title="Quitar de Tanda">
                                <RadioButtonUncheckedIcon />
                            </Tooltip>
                        </IconButton>
                    )
                }
            },
        },
        { field: "nombre_completo", headerName: "EVALUADOR", minWidth: 300 },
        { field: "categoria", headerName: "CATEGORIA", minWidth: 150 },
    ]

    return (
        <div className={clases.tableContainer}>
            {data.length ? (
                <DataGrid
                    pagination
                    autoHeight={maximize}
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={data}
                    pageSize={pageData.records}
                    loading={loading}
                    columns={columns}
                    paginationMode="server"
                    isRowSelectable={false}
                    rowsPerPageOptions={[pageData.records]}
                    rowCount={pageData.records}
                    page={pageData.currentPage}
                />
            ) : (
                <div className={clases.noDataContainer}>
                    <h2 className={clases.noDataText}>No se encontraron alumnos</h2>
                </div>
            )}
        </div>
    )
}

export default ControlEvaluacionDanesTable
