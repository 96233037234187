import React, { useEffect, useState } from "react"
import Container from "../../Components/Container/Container"
import { fetchResource, usePermitions } from "../../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"

import { Typography, makeStyles } from "@material-ui/core"
import toast, { Toaster } from "react-hot-toast"
import AsistenciaUserTable from "./AsistenciaUserTable"
import moment from "moment"

import AsistenciaUserTopInfo from "./AsistenciaUserTopInfo"
import { useSelector } from "react-redux"

const Styles = makeStyles({
    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },
})

const AsistenciaUsuario = () => {
    const { role_id, user_id } = usePermitions()

    const state = useSelector((state) => ({ ...state["userReducer"] }))
    const { user } = state

    const [datosUser, setDatosUser] = useState([])
    const [loading, setLoading] = useState(true)
    const [suspendida, setSuspendida] = useState([
        { suspende_id: 1, fecha: "2022-09-01" },
        { suspende_id: 2, fecha: "2022-08-02" },
    ])
    const [periodo_inicial, setPeriodoInicial] = useState("2022-08-01T00:00:00") //hay que colocar el Tiempo/hora 00:00:00 para que tome bien la fecha
    const [periodo_final, setPeriodoFinal] = useState("2022-09-01T00:00:00")
    const [presentes, setPresentes] = useState([])
    const [clasesAnual, setClasesAnual] = useState([])
    const [datosClases, setDatosClases] = useState([])
    const [claseId, setClaseId] = useState([])
    const [anio, setAnio] = useState(moment().format("YYYY"))
    const [diasSemana, setDiasSemana] = useState(
        user.clase
            ? {
                  lunes: user.clase.lunes,
                  martes: user.clase.martes,
                  miercoles: user.clase.miercoles,
                  jueves: user.clase.jueves,
                  sabado: user.clase.sabado,
                  domingo: user.clase.domingo,
                  viernes: user.clase.viernes,
              }
            : []
    )
    const [porsentaje, setporsentaje] = useState("")
    const [asistencias, setAsistencias] = useState("")
    const [mesSelected, setMesSelected] = useState(moment().format("MM"))
    const [clasesMensuales, setClasesMensuales] = useState(0)

    const [conteoClases, setConteoClases] = useState({
        anualTotal: 0,
        anualDictadas: 0,
        anualPresente: 0,
        anualAusente: 0,
        mensualTotal: 0,
        mensualDictadas: 0,
        mensualPresente: 0,
        mensualAusente: 0,
    })

    const [clasesConfig, setClasesConfig] = useState({ inicio: "2024-02-01", fin: "2024-12-10" })

    const meses = [
        { mes: "Enero", value: "01" },
        { mes: "Febrero", value: "02" },
        { mes: "Marzo", value: "03" },
        { mes: "Abril", value: "04" },
        { mes: "Mayo", value: "05" },
        { mes: "Junio", value: "06" },
        { mes: "Julio", value: "07" },
        { mes: "Agosto", value: "08" },
        { mes: "Septiembre", value: "09" },
        { mes: "Octubre", value: "10" },
        { mes: "Noviembre", value: "11" },
        { mes: "Diciembre", value: "12" },
    ]

    useEffect(() => {
       
        if (user.clase_id > 0) {
            fetchUserAsistencias(user.clase_id)
        } else{
            console.log("user.claseID=0", user)
        }

    }, [mesSelected])

    const fetchUserAsistencias = async () => {
        console.log("user.claseID>>>>0")
        const [data, error] = await fetchResource("/users/asistencia/show", "get", true, null)
        console.log("ASIST DATA", data)
        if (data) {
            if (data.code == 200) {
                let asistencias = data.asistencias
                base(asistencias)
                setLoading(false)
            } else if (data.code == 404) {
                console.log("400")
            
                base()
                //   No tiene clase Asignada no hay Asistencia
                setLoading(false)
            }
        } else {
            console.log("else")
            base()

            setLoading(false)
        }
    }
    const base = (asistencia) => {
        console.log("MES ", mesSelected, "ANIO", anio)
        let mesYanio = anio + "-" + mesSelected //mes y año
        let diasMes = moment(mesYanio, "YYYY-MM").daysInMonth() //  cantidad de dias del mes
        let primerDiaMes = moment(mesYanio + "-01").format("YYYY-MM-DD")
        let ultimoDiaMes = moment(mesYanio + "-" + diasMes).format("YYYY-MM-DD")
        let anual = conteoCantidadClases(diasSemana, clasesConfig.inicio, clasesConfig.fin)
        let mensual = conteoCantidadClases(diasSemana, primerDiaMes, ultimoDiaMes)
        let mesBase = []
        let hoy = moment().format("YYYY-MM-DD")
        let diaMes = moment(new Date(primerDiaMes))
        let cont = 1
        let dia = diaMes.add(cont, "d").format("YYYY-MM-DD")
        let presente = 0
        let ausente = 0
        let anualPres = 0

        while (ultimoDiaMes >= dia) {
            let diaId = moment(dia).day()
            if (VerificaDia(diasSemana, diaId) == true) {
                if (dia <= hoy) {
                    // estado 1 o 0 presente o ausente se coloca cuando tengo asistencia
                    if (asistencia?.length > 0) {
                        let asist = asistencia.filter((x) => x.fecha == dia)
                        if (asist.length > 0) {
                            mesBase.push({ id: cont, dia: nombreDia(dia), dia_id: diaId, estado: 1, fecha: dia })
                            presente++

                        } else {
                            mesBase.push({ id: cont, dia: nombreDia(dia), dia_id: diaId, estado: 0, fecha: dia })
                            ausente++
                        }
                    } else {
                        mesBase.push({ id: cont, dia: nombreDia(dia), dia_id: diaId, estado: 0, fecha: dia })
                        ausente++

                    }
                } else {
                    // estado= 3 ese dia se dictan clases pero no llgo aun
                    mesBase.push({ id: cont, dia: nombreDia(dia), dia_id: diaId, estado: 3, fecha: dia })
              
                }
                cont = cont + 1
            } else {
                // estado =4 no se ditan clases ese dia
                //  mesBase.push({ id: cont, dia: nombreDia(dia), dia_id: diaId, estado: 4, fecha: dia })
            }

            dia = diaMes.add(1, "d").format("YYYY-MM-DD")
          
        }

        if (asistencia?.length > 0) {
            asistencia.map((x) => {
                if (moment(x.fecha).format("YYYY") == moment().format("YYYY") && x.estado == 1) {
                   
                    anualPres = anualPres + 1
                }
            })
        } else {
            anualPres = 0
        }

        setConteoClases({
            ...conteoClases,
            anualTotal: anual.total,
            anualDictadas: anual.dictadas,
            anualPresente: anualPres,
            mesTotal: mensual.total,
            mensualDictadas: mensual.dictadas,
            mensualPresente: presente,
            mensualAusente: ausente,
        })

        setAsistencias(mesBase)
    }

    const VerificaDia = (dat, dia) => {
        let datos = dat
        let verifica = false

        if (dia == 1 && datos.lunes == 1) {
            verifica = true
        }
        if (dia == 2 && datos.martes == 1) {
            verifica = true
        }
        if (dia == 3 && datos.miercoles == 1) {
            verifica = true
        }
        if (dia == 4 && datos.jueves == 1) {
            verifica = true
        }
        if (dia == 5 && datos.sabado == 1) {
            verifica = true
        }
        if (dia == 6 && datos.sabado == 1) {
            verifica = true
        }
        if (dia == 0 && datos.domingo == 1) {
            verifica = true
        }

        return verifica
    }
    const porsent = (clases, ausente, suspendidas) => {
        let asist = 0
        console.log(clases)
        console.log(ausente)
        console.log(suspendidas)
        asist = ((clases - suspendidas - ausente) * 100) / (clases - suspendidas)
        let asist2 = Math.ceil(asist) + " %"
    }
    const conteoCantidadClases = (dat, inicio1, fin1) => {
        let datos = dat
        let cantidad = 0
        let cantidadDictada = 0
        let c = 0
        let fechaInicial = moment(inicio1).format("YYYY-MM-DD")
        let fechaHoy = moment().format("YYYY-MM-DD")
        let fechaFin = moment(fin1).format("YYYY-MM-DD")

        while (fechaFin >= fechaInicial) {
          
            let dia = moment(fechaInicial).day()

            if (dia == 1 && datos.lunes == 1) {
                cantidad++
                if (fechaHoy >= fechaInicial) {
                    if (clasesConfig.inicio <= inicio1) {
                        cantidadDictada++
                    }
                }
            }
            if (dia == 2 && datos.martes == 1) {
                cantidad++
                if (fechaHoy >= fechaInicial) {
                    if (clasesConfig.inicio <= inicio1) {
                        cantidadDictada++
                    }
                }
            }
            if (dia == 3 && datos.miercoles == 1) {
                cantidad++
                if (fechaHoy >= fechaInicial) {
                    if (clasesConfig.inicio <= inicio1) {
                        cantidadDictada++
                    }
                }
            }
            if (dia == 4 && datos.jueves == 1) {
                cantidad++
                if (fechaHoy >= fechaInicial) {
                    if (clasesConfig.inicio <= inicio1) {
                        cantidadDictada++
                    }
                }
            }
            if (dia == 5 && datos.sabado == 1) {
                cantidad++
                if (fechaHoy >= fechaInicial) {
                    if (clasesConfig.inicio <= inicio1) {
                        cantidadDictada++
                    }
                }
            }
            if (dia == 6 && datos.sabado == 1) {
                cantidad++
                if (fechaHoy >= fechaInicial) {
                    if (clasesConfig.inicio <= inicio1) {
                        cantidadDictada++
                    }
                }
            }
            if (dia == 0 && datos.domingo == 1) {
                cantidad++
                if (fechaHoy >= fechaInicial) {
                    if (clasesConfig.inicio <= inicio1) {
                        cantidadDictada++
                    }
                }
            }

            c++
            fechaInicial = moment(inicio1).add(c, "d").format("YYYY-MM-DD")
        }

        return { total: cantidad, dictadas: cantidadDictada }
    }
    const nombreDia = (fecha) => {
        let n = moment(fecha).day()

        switch (n) {
            case 1:
                return "Lunes"
                break
            case 2:
                return "Martes"
                break
            case 3:
                return "Miercoles"
                break
            case 4:
                return "Jueves"
                break
            case 5:
                return "Viernes"
                break
            case 6:
                return "Sabado"
                break
            case 0:
                return "Domingo"
                break
            default:
                return "No Definido"
                break
        }
    }
    const handleMeses = (x) => {
        setMesSelected(x)
    }

    const classes = Styles()
    return (
        <div className="alumnos-container">
            <Container title={"Control de Tu Asistencia"} more>
                <Toaster position="center-center" />
                <AsistenciaUserTopInfo
                    meses={meses}
                    conteo={conteoClases}
                    disabled={asistencias.length ? false : true}
                    mesSeleccion={mesSelected}
                    handleMeses={(x) => handleMeses(x)}
                />

                <Typography variant="h6" style={{ marginBottom: 20, textAlign: "center" }}>
                    ¿Tenes Dudas Sobre Tu Asistencia? No dudes en consultale a tu Instructor.{" "}
                </Typography>

                {claseId !== null ? (
                    <AsistenciaUserTable data={asistencias} loading={loading} />
                ) : (
                    <Typography>NO TIENE CLASE ASIGNADA</Typography>
                )}
            </Container>
        </div>
    )
}

export default AsistenciaUsuario
