import React, { useState } from "react"
import { fetchResource } from "../../../Functions/Functions"
import CreateExamForm from "./CreateExamForm"
import { CircularProgress } from "@material-ui/core"

const CreateExamHandler = ({ setListaExamenes, listaExamenes }) => {
    const tipoExamen = [
        { id: "Gups", nombre: "Gups" },
        { id: "Danes", nombre: "Danes" },
        { id: "Mixto", nombre: "Mixto" },
    ]

    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(false)
    const [examen, setExamen] = useState({
        fecha: "",
        valor_examen: "",
        lugar: "",
        tipo: "",
        direccion_calle: "",
        direccion_numero: "",
        direccion_localidad: "",
        direccion_detalle: "",
    })

    const handleChange = (e) => {
        setExamen({
            ...examen,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        console.log(examen)
        const [data, error] = await fetchResource("/director/examen/create", "post", true, examen)

        if (data) {
            setLoading(false)
            setListaExamenes([...listaExamenes, data.examen])
        } else {
            setLoading(false)
            setErrors(true)
            console.log(error)
        }

        setExamen({
            fecha: "",
            valor_examen: "",
            lugar: "",
            tipo: "",
            direccion_calle: "",
            direccion_numero: "",
            direccion_localidad: "",
            direccion_detalle: "",
        })
    }
    if (loading) {
        return <CircularProgress color="secondary" />
    } else {
        return (
            <CreateExamForm
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                tipoExamen={tipoExamen}
                examen={examen}
                error={errors}
            />
        )
    }
}

export default CreateExamHandler
