import React from "react"
import { Button, makeStyles, TextField } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"

const Styles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
        gap: "1em",
    },
    button: {
        width: "210px",
        backgroundColor: "#e00",
    },
    registro: {
        borderTopStyle: "solid",
        borderColor: "#fffb",
        borderWidth: "1px",
        paddingTop: "5px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    forgot: {
        textDecoration: "none",
        color: "#0099ff",
    },
})

const CreateCentroForm = ({ centro, handleChange, handleSubmit, error, edit, handleCancel }) => {
    const style = Styles()

    return (
        <form onSubmit={handleSubmit} className={style.form} autoComplete="nope">
            {error && (
                <Alert variant="filled" color="error">
                    Verifique los datos ingresados
                </Alert>
            )}
            <TextField
                autoComplete="nope"
                id="nombre"
                name="nombre"
                label="Nombre"
                value={centro.nombre}
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="direccion"
                name="direccion"
                label="Direccion"
                value={centro.direccion}
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="localidad"
                name="localidad"
                label="Localidad"
                value={centro.localidad}
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
                required
            />
            <TextField
                autoComplete="nope"
                id="zona"
                name="zona"
                value={centro.zona}
                label="Zona/Barrio"
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
            />
            <TextField
                autoComplete="nope"
                id="telefono"
                name="telefono"
                value={centro.telefono}
                label="Telefono"
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
            />
            <TextField
                autoComplete="nope"
                id="contacto"
                name="contacto"
                value={centro.contacto}
                label="Encargado"
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
            />
            <TextField
                autoComplete="nope"
                id="email"
                name="email"
                value={centro.email}
                label="Mail"
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
            />
            <TextField
                autoComplete="nope"
                id="web"
                name="web"
                value={centro.web}
                label="Web"
                onChange={handleChange}
                type="text"
                variant="outlined"
                size="small"
                color="secondary"
            />
            <Button type="submit" variant="contained" className={style.button} color="primary">
                {" "}
                {edit ? "Modificar" : "Crear"}
            </Button>
            <Button type="button" onClick={handleCancel} variant="contained" className={style.button} color="primary">
                {" "}
                {"Cancelar"}
            </Button>
        </form>
    )
}

export default CreateCentroForm
