import React, { useEffect, useState } from "react"

import { fetchResource, usePermitions } from "../../Functions/Functions"
import CircularProgress from "@material-ui/core/CircularProgress"
import Box from "@mui/material/Box"
import { useSelector } from "react-redux"
import { makeStyles, Select, MenuItem, option, Typography, TextField, Button } from "@material-ui/core"
import toast, { Toaster } from "react-hot-toast"
import Rating from "@mui/material/Rating"
import { styled } from "@mui/material/styles"
import { useLocation } from "react-router-dom"
import moment from "moment"

import FavoriteIcon from "@mui/icons-material/Favorite"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"

const Styles = makeStyles({
    rankingMensual: {
        "&.MuiRating-root": {
            fontSize: "2.5rem",
        },
    },

    rankingAnual: {
        "&.MuiRating-root": {
            fontSize: "4rem",
        },
    },

    rankingAnualRojo: {
        "&.MuiRating-root": {
            fontSize: "4rem",
            color: "red",
        },
    },
    rankingMensualRojo: {
        "&.MuiRating-root": {
            fontSize: "2.5rem",
            color: "orange",
        },
    },

    filtersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        minWidth: "100%",
        flexWrap: "wrap",
    },
    filterInput: {
        alignSelf: "flex-end",
    },
    recordCounter: {
        alignSelf: "end",
    },
    ageInputs: {
        maxWidth: "5em",
    },
    ctaButton: {
        backgroundColor: "red",
    },

    top: {
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 5,
        marginBottom: 20,
        marginTop: 10,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 10,
        "@media (min-width: 1200px)": { flexDirection: "row" },
    },
})

const AsistenciaUserTopInfo = (props) => {
    const {
        btnvolver,
        meses,
        disabled,
        handleMeses,
        mesSeleccion,
        conteo,
        permitido,
        btnalumnos,
        datos,
        fecha,
        nombreClase,
        changeFecha,
    } = props

    const state = useSelector((state) => ({ ...state["basicDataReducer"] }))
    const { basicData } = state
    const { role_id } = usePermitions()
    const location = useLocation()
    const examenesActivos = location.state
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [alumnos, setAlumnos] = useState([]) // guarda los id y nombres de los alumnos
    const [clases, setClases] = useState("") // guarda todas las clases que va a mostrar en Select
    const [records, setRecords] = useState(0)
    const [modal, setModal] = useState(false)
 
    const porsentajes = (tipo) => {
        let n = 0
        if (tipo == 1) {
            n = ((conteo.mensualPresente * 100) / conteo.mensualDictadas).toFixed(1)
        }
        if (tipo == 2) {
            n = ((conteo.anualPresente * 100) / conteo.anualDictadas).toFixed(1)
        }
        let r = 0
        if (n > 0) {
            r = n
        }
        return r
    }

    console.log({ conteo })

    const classes = Styles()
     console.log("porsentajjjjj ",porsentajes(2)/10) 
    return (
        <div className={classes.top}>
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Select
                    disabled={disabled}
                    style={{ width: 300, fontSize: 40, textAlign: "center", justifyItems: "center" }}
                    value={mesSeleccion}
                    onChange={(x) => handleMeses(x.target.value)}
                >
                    {meses.map((x) => {
                        return (
                            <MenuItem id={x.value} value={x.value}>
                                {x.mes}
                            </MenuItem>
                        )
                    })}
                </Select>
            </div>

            <Box
                style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    margin: 20,
                }}
            >
                <Typography>ASISTENCIA MENSUAL : {porsentajes(1)}%</Typography>
                <Rating
                    className={porsentajes(1) / 10 / 2 ? classes.rankingMensual : classes.rankingMensualRojo}
                    readOnly
                    precision={0.5}
                    name="ranking"
                    value={porsentajes(1) / 10 / 2}
                    max={5}
                />
            </Box>

            <Box
                style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    margin: 20,
                }}
            >
                <Typography>ASISTENCIA ANUAL: {porsentajes(2)}%</Typography>
                <Rating
                    className={porsentajes(2) / 10  >= 1 ? classes.rankingAnual : classes.rankingAnualRojo}
                    readOnly
                    precision={0.5}
                    name="ranking"
                    value={porsentajes(2) / 10 }
                    max={5}
                />
            </Box>
        
        </div>
    )
}

export default AsistenciaUserTopInfo
